import { Box, Button, CardContent, Grid, Modal, Typography } from '@mui/material';
import CustomFormLabel from '../forms/theme-elements/CustomFormLabel';
import CustomTextField from '../forms/theme-elements/CustomTextField';
import Mustache from 'mustache';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { CreateCampaignFormValue } from '@features/Admin/Campaign/model/type';
import { FormFields } from '@shared/types/admin/campaign';

type mastecheValues = {
  agentName: string;
  templateTextFieldValue: string;
  campaignName: string;
};

interface CampaignTextField {
  mastecheValues: mastecheValues;
  setTemplateTextFieldValue: React.Dispatch<React.SetStateAction<string>>;
  setValue: UseFormSetValue<CreateCampaignFormValue>;
  control: Control<CreateCampaignFormValue, any>;
}

export const CampaignTextField = ({
  mastecheValues,
  setTemplateTextFieldValue,
  setValue,
  control,
}: CampaignTextField) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [renderedTextFieldValue, setRenderedTextFieldValue] = useState('');

  const getCursorPosition = (input: HTMLInputElement) => {
    if ('selectionStart' in input) {
      return input.selectionStart || 0;
    }
    return 0;
  };

  const insertAtCursor = (input: HTMLInputElement, text: string) => {
    const cursorPos = getCursorPosition(input);
    const value = input.value;
    input.value = value.slice(0, cursorPos) + text + value.slice(cursorPos);
    input.setSelectionRange(cursorPos + text.length, cursorPos + text.length);
    input.focus();
  };

  Mustache.tags = ['[[%', '%]]'];

  const agentHumanNamePlaceholder = '[[%agent_human_name%]]';
  const companyNamePlaceholder = '[[%company_name%]]';
  const campaignNamePlaceholder = '[[%campaign_name%]]';

  const values = {
    agent_human_name: mastecheValues.agentName,
    // @TODO: use real data
    company_name: 'COMPANY NAME',
    campaign_name: mastecheValues.campaignName,
  };

  const handleAddAgentHumanName = () => {
    // setTemplateTextFieldValue((prev) => prev + agentHumanNamePlaceholder);
    const input = document.getElementById('campaign-template') as HTMLInputElement;
    if (input) {
      insertAtCursor(input, agentHumanNamePlaceholder);
      setTemplateTextFieldValue(input.value);
    }
  };

  const handleAddCompanyName = () => {
    // setTemplateTextFieldValue((prev) => prev + companyNamePlaceholder);
    const input = document.getElementById('campaign-template') as HTMLInputElement;
    if (input) {
      insertAtCursor(input, companyNamePlaceholder);
      setTemplateTextFieldValue(input.value);
    }
  };

  const handleAddCampaignName = () => {
    // setTemplateTextFieldValue((prev) => prev + campaignNamePlaceholder);
    const input = document.getElementById('campaign-template') as HTMLInputElement;
    if (input) {
      insertAtCursor(input, campaignNamePlaceholder);
      setTemplateTextFieldValue(input.value);
    }
  };

  useEffect(() => {
    setValue('followUpTemplate' as FormFields, mastecheValues.templateTextFieldValue);
  }, [mastecheValues.templateTextFieldValue]);

  const handleOnChangeTextAria = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTemplateTextFieldValue(value as string);
    setValue(name as FormFields, value);
  };

  // open/close modal
  const handleOpenModal = () => {
    const renderedText = Mustache.render(mastecheValues.templateTextFieldValue, values);
    setRenderedTextFieldValue(renderedText);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Grid item xs={12} lg={12}>
      <CardContent>
        <CustomFormLabel
          sx={{
            mt: 0,
          }}
          htmlFor="campaign-template">
          {t('campaignPage.template')}:
        </CustomFormLabel>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'start',
            alignItems: 'start',
          }}>
          <Button
            onClick={handleAddAgentHumanName}
            sx={{ marginRight: '10px', marginBottom: '10px' }}
            color="primary"
            variant="contained">
            {`add ${agentHumanNamePlaceholder}`}
          </Button>
          <Button
            onClick={handleAddCompanyName}
            sx={{ marginRight: '10px', marginBottom: '10px' }}
            color="primary"
            variant="contained">
            {`add ${companyNamePlaceholder}`}
          </Button>
          <Button
            sx={{ marginBottom: '10px' }}
            onClick={handleAddCampaignName}
            color="primary"
            variant="contained">
            {`add ${campaignNamePlaceholder}`}
          </Button>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <CustomTextField
            sx={{ marginRight: '10px' }}
            value={mastecheValues.templateTextFieldValue}
            control={control}
            name="followUpTemplate"
            onChange={handleOnChangeTextAria}
            id="campaign-template"
            variant="outlined"
            fullWidth
            required
            multiline
            rows={5}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
            <Button sx={{ borderRadius: '100px' }} onClick={handleOpenModal}>
              {t('campaignPage.preview')}
            </Button>
          </Box>
        </Box>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}>
            <Typography id="modal-modal-description">{renderedTextFieldValue}</Typography>
          </Box>
        </Modal>
      </CardContent>
    </Grid>
  );
};
