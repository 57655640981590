import React from 'react';
import { AuthLayout } from '@shared/layouts';
import { SignInForm } from '@features/Auth';
import { useSuperAdminSignIn } from '@features/Auth/SuperAdminSignIn/hooks/useSuperAdminSignIn';

export const SuperAdminSignInPage: React.FC = () => {
  const { onSubmit, control, setValue, errorMsg, formState } = useSuperAdminSignIn();

  return (
    <AuthLayout>
      <SignInForm
        title="Sign In (as Root Admin)"
        onSubmit={onSubmit}
        control={control}
        setValue={setValue}
        errorMsg={errorMsg}
        formState={formState}
      />
    </AuthLayout>
  );
};
