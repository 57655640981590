import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './styles';
import CompanyForm from './components/CreateCompanyForm';
import { useTranslation } from 'react-i18next';

interface CreateCompanyPopupProps {
  showDialogFlag: boolean;
  closeDialog: () => void;
}

export const CreateCompanyPopup: React.FC<CreateCompanyPopupProps> = ({
  showDialogFlag,
  closeDialog,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={showDialogFlag}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      aria-describedby="form-dialog-description"
      className={classes.dialog}>
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        {t('superAdminMainPage.createCompany.operation.createCompany')}
        <IconButton className={classes.customizedButton} onClick={closeDialog} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <CompanyForm closeDialog={closeDialog} />
      </DialogContent>
      <DialogActions>
        <IconButton className={classes.customizedButton} onClick={closeDialog} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};
