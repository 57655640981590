import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconTrash, IconExternalLink, IconArrowsSort } from '@tabler/icons-react';

import { AuthenticatedPath } from '@shared/constants/routes.constants';
import BlankCard from '@shared/components/shared/BlankCard';
import {
  AgentDto,
  useDeleteAgentControllerDeleteAgentMutation,
} from '@shared/services/apiService/apiService';
import CustomAlert from '@shared/components/alert/alert';
import { AgentListProps, ResponseMsg, SortingAgentColumns } from '@shared/types/admin/agent';

import { useStyles } from './styles';

export const AgentList: React.FC<AgentListProps> = ({ setLoading, agentList }: AgentListProps) => {
  const [useDeleteAgent, { isLoading }] = useDeleteAgentControllerDeleteAgentMutation();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigator = useNavigate();

  const [responseMsg, setResponseMsg] = useState<ResponseMsg>({
    message: '',
    type: 'success',
  });

  const [sortOrder, setSortOrder] = useState(true);
  const [agents, setAgents] = useState<AgentDto[]>(agentList);
  const [showDialog, setShowDialog] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setAgents(agentList);
  }, [agentList]);

  const handleSort = (field: SortingAgentColumns) => {
    setSortOrder(!sortOrder);
    const sortedAgents = sortArray(agents, field, sortOrder);
    setAgents(sortedAgents);
  };

  const sortArray = (arr: AgentDto[], field: SortingAgentColumns, ascending = true) => {
    return [...arr].sort((a: AgentDto, b: AgentDto): number => {
      if (field === 'voice.name') {
        if (a.voice.name < b.voice.name) return ascending ? -1 : 1;
        if (a.voice.name > b.voice.name) return ascending ? 1 : -1;
        return 0;
      }

      if (a[field] < b[field]) return ascending ? -1 : 1;
      if (a[field] > b[field]) return ascending ? 1 : -1;
      return 0;
    });
  };

  const handleDeleteBtn = (id: string) => {
    setShowDialog(true);
    setDeleteId(id);
    setAnchorEl(null);
  };

  const deleteAgent = async () => {
    if (deleteId) {
      setShowDialog(false);
      setLoading(true);
      const response = await useDeleteAgent({ id: deleteId });
      if ('error' in response) {
        setResponseMsg({
          message: 'Failed to delete agent!',
          type: 'error',
        });
      } else {
        setResponseMsg({
          message: 'Deleted agent successfully.',
          type: 'success',
        });
      }
    }
    setDeleteId('');
    setLoading(false);
  };

  const handleMoreBtn = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleRowDoubleClick = (rowId: string) => {
    navigator(`/admin/agent/edit?id=${rowId}`);
  };

  return (
    <BlankCard className={classes.agent}>
      <Box className={classes.agent_header}>
        <Box className={classes.agent_header_title}>
          <Typography fontSize="20px">{t('adminMainPage.agents.title')}</Typography>
        </Box>
        <Tooltip title="Add Agent" arrow>
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              navigator(AuthenticatedPath.CREATE_AGENT);
            }}>
            {t('adminMainPage.operation.addAgent')}
          </Button>
        </Tooltip>
      </Box>
      <TableContainer className={classes.agent_table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                onClick={() => handleSort('name')}
                className={classes.agent_table_first_cell}>
                <Box className={classes.agent_table_sort_cell}>
                  <Typography variant="h6">{t('adminMainPage.agents.agentName')}</Typography>
                  <IconArrowsSort size="18" />
                </Box>
              </TableCell>
              <TableCell onClick={() => handleSort('voice.name')}>
                <Box className={classes.agent_table_sort_cell}>
                  <Typography variant="h6">{t('adminMainPage.agents.voiceLanguage')}</Typography>
                  <IconArrowsSort size="18" />
                </Box>
              </TableCell>
              <TableCell onClick={() => handleSort('model')}>
                <Box className={classes.agent_table_sort_cell}>
                  <Typography variant="h6">{t('adminMainPage.agents.agentModel')}</Typography>
                  <IconArrowsSort size="18" />
                </Box>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  {t('adminMainPage.operation.action')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agents?.map((row) => (
              <TableRow
                className={classes.agent_table_row}
                key={row.id}
                hover
                onDoubleClick={() => handleRowDoubleClick(row.id)}>
                <TableCell align="left" className={classes.agent_table_first_cell}>
                  <Typography variant="subtitle1">{row.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.voice.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.model}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="More actions">
                    <IconButton aria-describedby={row.id.toString()} onClick={handleMoreBtn}>
                      <MoreVertIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <Popover
                  id={row.id.toString()}
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}>
                  <Box className={classes.agent_table_popover}>
                    <IconButton
                      className={classes.agent_table_popover_button}
                      onClick={() => navigator('/admin/agent/edit?id=' + row.id)}>
                      <IconExternalLink size="24px" />
                      <Typography>{t('adminMainPage.operation.open')}</Typography>
                    </IconButton>
                    <IconButton
                      className={classes.agent_table_popover_button}
                      onClick={() => {
                        handleDeleteBtn(row.id);
                      }}>
                      <IconTrash size="24px" color="#FA896B" />
                      <Typography color="#FA896B">{t('adminMainPage.operation.delete')}</Typography>
                    </IconButton>
                  </Box>
                </Popover>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        {responseMsg.message ? (
          <CustomAlert message={responseMsg.message} type={responseMsg.type} />
        ) : (
          ''
        )}
      </div>
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{t('dialog.title3')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t('dialog.content')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setShowDialog(false)}>
            {t('adminMainPage.operation.cancel')}
          </Button>
          <Button onClick={deleteAgent} autoFocus>
            {t('adminMainPage.operation.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </BlankCard>
  );
};
