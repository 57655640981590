import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '@pages/Authentication/auth1/Login';
import { ForgotPassword, ResetPassword, VerifyCode } from '@pages/Forgot';
import { SignInPage } from '@pages/SignIn';
import {
  AuthPath,
  UnauthenticatedPath,
  SuperAdminUnauthenticatedPath,
} from '@shared/constants/routes.constants';
import { SuperAdminSignInPage } from '@pages/SuperAdminSignIn';

export const UnauthorizedRoutes = () => {
  return (
    <Routes>
      {/* Example routes of Modernize Pages */}
      <Route path="/" element={<SignInPage />} />

      <Route path={AuthPath.SIGN_IN} element={<Login />} />
      <Route path={UnauthenticatedPath.SIGN_IN} element={<SignInPage />} />
      <Route path={UnauthenticatedPath.FORGOT} element={<ForgotPassword />} />
      <Route path={UnauthenticatedPath.VERIFY} element={<VerifyCode />} />
      <Route path={UnauthenticatedPath.RESET} element={<ResetPassword />} />
      <Route path="*" element={<Navigate to={UnauthenticatedPath.SIGN_IN} replace />} />

      <Route path={SuperAdminUnauthenticatedPath.SIGN_IN} element={<SuperAdminSignInPage />} />
    </Routes>
  );
};
