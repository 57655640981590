import { Link } from 'react-router-dom';

import { Box, Typography, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { IconPower } from '@tabler/icons-react';

import { RootState, useAppSelector } from '@shared/store';
import { useSignOut } from '@features/Auth/SignOut/hooks/useSignOut';

export const Profile = () => {
  const customizer = useAppSelector((state: RootState) => state.customizer);
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
  const hideMenu = lgUp ? customizer.isCollapse && !customizer.isSidebarHover : '';
  const onLogout = useSignOut();

  return (
    <Box
      display={'flex'}
      justifyContent="center"
      alignItems="center"
      gap={2}
      sx={{ m: 3, p: 2, bgcolor: `${'secondary.light'}` }}>
      {!hideMenu ? (
        <>
          <Box>
            <Typography fontSize="16px" variant="h6">
              Logout
            </Typography>
          </Box>
          <Box>
            <Tooltip title="Logout" placement="top">
              <IconButton
                onClick={onLogout}
                color="primary"
                component={Link}
                to=""
                aria-label="logout"
                size="small">
                <IconPower size="20" />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      ) : (
        ''
      )}
    </Box>
  );
};
