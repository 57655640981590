import dayjs from 'dayjs';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import 'dayjs/locale/en';

import CloseIcon from '@mui/icons-material/Close';

import CustomCheckbox from '@shared/components/forms/theme-elements/CustomCheckbox';
import { useStyles } from './styles';
import { CallScheduleRequestDto } from '@shared/services/apiService/apiService';
import { UseFormSetValue } from 'react-hook-form';
import { CreateCampaignFormValue } from '@features/Admin/Campaign/model/type';

interface SchedulePopupProps {
  showDialogFlag: boolean;
  closeDialog: () => void;
  setValue?: UseFormSetValue<CreateCampaignFormValue>;
  schedule: CallScheduleRequestDto;
  setSchedule: React.Dispatch<React.SetStateAction<CallScheduleRequestDto>>;
  startTime?: string;
  endTime?: string;
}

export const SchedulePopup: React.FC<SchedulePopupProps> = ({
  showDialogFlag,
  closeDialog,
  setValue,
  schedule,
  setSchedule,
  startTime,
  endTime,
}: SchedulePopupProps) => {
  const { classes } = useStyles();
  const week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const getTimeFromSchedule = (day: string, type: 'Start' | 'End') => {
    const dayKey = day.toLowerCase() as keyof CallScheduleRequestDto;
    const key = type === 'Start' ? `${dayKey}Start` : `${dayKey}End`;

    if (key in schedule) {
      return schedule[key as keyof CallScheduleRequestDto];
    }

    return '09:00';
  };

  const handleTimeChange = (day: string, period: 'Start' | 'End', newValue: any) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [`${day.toLowerCase()}${period}` as keyof CallScheduleRequestDto]: newValue
        ? newValue.format('HH:mm')
        : null,
    }));
  };

  const handleSave = () => {
    if (setValue) {
      setValue('callSchedule', schedule);
    }
    closeDialog();
  };

  return (
    <Dialog
      className={classes.dialog}
      open={showDialogFlag}
      onClose={() => closeDialog()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle} variant="h6">
        Call Schedule
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.table_header_cell}>
                  <Typography className={classes.columnName} variant="h6">
                    Week
                  </Typography>
                </TableCell>
                <TableCell className={classes.table_header_cell}>
                  <Typography className={classes.columnName} variant="h6">
                    From
                  </Typography>
                </TableCell>
                <TableCell className={classes.table_header_cell}>
                  <Typography className={classes.columnName} variant="h6">
                    To
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {week.map((day, index) => {
                return (
                  <TableRow key={day}>
                    <TableCell className={classes.table_row_cell}>
                      <FormControlLabel control={<CustomCheckbox defaultChecked />} label={day} />
                    </TableCell>
                    <TableCell className={classes.table_row_cell}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                        <DemoContainer components={['TimePicker']}>
                          <DesktopTimePicker
                            // defaultValue={dayjs(`2022-04-17T${startTime}`)}
                            value={dayjs(`2022-04-17T${getTimeFromSchedule(day, 'Start')}`)}
                            onChange={(newValue) => {
                              return handleTimeChange(day, 'Start', newValue);
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </TableCell>
                    <TableCell className={classes.table_row_last_cell}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                        <DemoContainer components={['TimePicker']}>
                          <DesktopTimePicker
                            // defaultValue={dayjs(`2022-04-17T${endTime}`)}
                            value={dayjs(`2022-04-17T${getTimeFromSchedule(day, 'End')}`)}
                            onChange={(newValue) => {
                              return handleTimeChange(day, 'End', newValue);
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction="row" justifyContent="space-between">
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
          <Button onClick={closeDialog} variant="outlined">
            Close
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <IconButton
          className={classes.customizedButton}
          onClick={() => {
            closeDialog();
          }}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};
