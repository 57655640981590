import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { useResetPasswordControllerResetPasswordMutation } from '@shared/services/apiService/apiService';
import { ResetFormValue } from '../modal/type';

export const useResetPassword = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [useResetPassword] = useResetPasswordControllerResetPasswordMutation();
  const [errorMsg, setErrorMsg] = useState('');
  const [successMSg, setSuccessMsg] = useState('');

  const { handleSubmit, setValue, ...formMethods } = useForm<ResetFormValue>({
    mode: 'onTouched',
    defaultValues: {
      password: '',
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    const token = searchParams.get('token');
    const res: any = await useResetPassword({
      resetPasswordRequestDto: {
        token: token ? token : '',
        password: data.password,
      },
    });

    if (res.error) {
      setSuccessMsg('');
      if (res.error.data) {
        setErrorMsg(res.error.data.message);
      } else {
        setErrorMsg('An unknown error occurred.');
      }
    } else {
      setErrorMsg('');
      setSuccessMsg('Reset password successfully!');
    }
  });

  return {
    setValue,
    onSubmit,
    errorMsg,
    successMSg,
    ...formMethods,
  };
};
