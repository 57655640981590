import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import { useTranslation } from 'react-i18next';
import readXlsxFile from 'read-excel-file';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import {
  MenuItem,
  Typography,
  Grid,
  Button,
  Stack,
  CardContent,
  Chip,
  Tooltip,
  LinearProgress,
  Snackbar,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';

import { IconCalendarEvent, IconFile, IconPlus } from '@tabler/icons-react';

import { SchedulePopup } from '@widgets/Popup/Schedule/Schedule';
import { AuthenticatedPath } from '@shared/constants/routes.constants';
import PageContainer from '@shared/components/container/PageContainer';
import Breadcrumb from '@shared/layouts/FullLayout/shared/breadcrumb/Breadcrumb';
import CustomSelect from '@shared/components/forms/theme-elements/CustomSelect';
import CustomFormLabel from '@shared/components/forms/theme-elements/CustomFormLabel';
import CustomTextField from '@shared/components/forms/theme-elements/CustomTextField';
import BlankCard from '@shared/components/shared/BlankCard';

import { useStyles } from './styles';
import { useCreateCampaign } from '@features/Admin/Campaign/hooks';
import { FormFields } from '@shared/types/admin/campaign';
import Spinner from '@shared/components/spinner/Spinner';
import {
  CallScheduleRequestDto,
  LeadListSimpleDto,
  useGetAllAgentsControllerGetAllAgentsQuery,
} from '@shared/services/apiService/apiService';
import { ConfirmDeleteDialog } from '@shared/components/confirmDeleteDialog/ConfirmDeleteDialog';
import { CampaignTextField } from '@shared/components/campaignTextField/campaignTextField';
import CustomAlert from '@shared/components/alert/alert';
import { useCampaignLogic } from '@features/Admin/Campaign/hooks/useCampaignLogic';

type Column = {
  [key: string]: any;
};

type FileData = {
  id: number;
  fileName: string;
  columns: Column;
};

interface ScheduleState extends CallScheduleRequestDto {}

export const startTime = '09:00:00';
export const endTime = '18:00:00';

export const initialSchedule: ScheduleState = {
  mondayStart: startTime,
  mondayEnd: endTime,
  tuesdayStart: startTime,
  tuesdayEnd: endTime,
  wednesdayStart: startTime,
  wednesdayEnd: endTime,
  thursdayStart: startTime,
  thursdayEnd: endTime,
  fridayStart: startTime,
  fridayEnd: endTime,
  saturdayStart: startTime,
  saturdayEnd: endTime,
  sundayStart: startTime,
  sundayEnd: endTime,
  // @ts-ignore
  timezone: null,
};

export const CreateCampaignPage: React.FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigator = useNavigate();

  const {
    onSubmit,
    setValue,
    control,
    isLoading,
    retriesPerDayError,
    retryIntervalInMinutesError,
    retryMaxNumberError,
    setRetriesPerDayError,
    setRetryIntervalInMinutesError,
    setRetryMaxNumberError,
    excelFieldError,
    setExcelFieldError,
  } = useCreateCampaign();

  const { data: agentList } = useGetAllAgentsControllerGetAllAgentsQuery();

  const {
    inputFilesNameStorage,
    setInputFilesNameStorage,
    inputFile,
    fileError,
    listError,
    handleAddFileData,
    setListError,
    setFileError,
  } = useCampaignLogic();

  const BCrumb = [
    {
      to: AuthenticatedPath.CAMPAIGN,
      title: t('campaignPage.header.campaign'),
    },
    {
      title: t('action.create'),
    },
  ];

  const [showScheduleDilaogFlag, setShowScheduleDialogFlag] = useState(false);
  const [listDeleteDialog, setListDeleteDialog] = useState(false);
  const [selectedListIdx, setListIdx] = useState<number | null>(null);
  const [fileDataArray, setFileDataArray] = useState<FileData[]>([]);
  const [numbersLists, setNumbersLists] = useState<LeadListSimpleDto[]>([]);
  const [fileUploadingProgress, setfileUploadingProgress] = useState(0);
  const [countOfPhoneNumbers, setCountOfPhoneNumbers] = useState(0);
  const [snackBarShow, setSnackBarShow] = useState(false);
  const [agent, setAgent] = useState('');
  const [isFile, setIsFile] = useState(false);
  const [fileNameToDelete, setFileNameToDelete] = useState('');

  const [retryMaxNumberValue, setRetryMaxNumberValue] = useState('');
  const [retriesPerDayValue, setRetriesPerDayValue] = useState('');
  const [retryIntervalInMinutesValue, setRetryIntervalInMinutesValue] = useState('');
  const [agentName, setAgentName] = useState('');
  const [templateTextFieldValue, setTemplateTextFieldValue] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [campaignNameError, setCampaignNameError] = useState('');

  useEffect(() => {
    if (agentList) {
      setAgent(agentList[0].id);
      setAgentName(agentList[0].mission.humanName);
      setValue('agentId' as FormFields, agentList[0].id);
    }
  }, [agentList]);

  const [schedule, setSchedule] = useState<CallScheduleRequestDto>(initialSchedule);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setfileUploadingProgress(10);
    const file = e.target.files && e.target.files[0];

    let fileName = file?.name;
    if (fileName) {
      if (fileName in inputFilesNameStorage) {
        setfileUploadingProgress(0);
        return;
      } else {
        setInputFilesNameStorage((prevState) => ({ ...prevState, [fileName!]: fileName! }));
      }
    }

    if (!file) {
      return;
    }

    setIsFile(true);

    setfileUploadingProgress(80);

    readXlsxFile(file).then((rows: Column) => {
      if (rows[0][0] !== 'phone') {
        setListError('Column name should be "phone"');
        setfileUploadingProgress(0);
        return;
      }
      const columnNames = rows[0] as string[];
      let columns: Column = {};

      setfileUploadingProgress(60);

      columnNames.forEach((name) => {
        columns[name] = [];
      });

      for (let i = 1; i < rows.length; i++) {
        let row = rows[i];
        for (let j = 0; j < row.length; j++) {
          let cell = row[j];
          let columnName = columnNames[j];

          const phoneNumber = String(cell || '').trim();

          if (columnName && columnName.toLowerCase() === 'phone' && phoneNumber) {
            const parsedPhoneNumber = parsePhoneNumberFromString(
              phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`,
            );

            columns[columnName].push(parsedPhoneNumber?.format('E.164'));

            setCountOfPhoneNumbers(columns[columnName].length);
          }
        }
      }

      const fileData: FileData = {
        id: fileDataArray.length,
        fileName: file.name,
        columns: columns,
      };

      setfileUploadingProgress(100);
      setFileDataArray((prev) => [...prev, fileData]);

      setTimeout(() => {
        setfileUploadingProgress(0);
      }, 500);

      setSnackBarShow(true);
    });

    if (inputFile.current) {
      inputFile.current.value = '';
    }
  };

  const onDragDropEnds = (oldIndex: number | undefined, newIndex: number | undefined) => {
    if (typeof oldIndex === 'number' && typeof newIndex === 'number') {
      const newArray = [...fileDataArray];

      const [removedItem] = newArray.splice(oldIndex, 1);

      newArray.splice(newIndex, 0, removedItem);
      const updatedArray = newArray.map((item, index) => ({ ...item, id: index }));

      setFileDataArray(updatedArray);
    }
  };

  const handleDeleteList = (id: number, fileName: string) => {
    setListDeleteDialog(true);
    setListIdx(id);
    if (Object.keys(inputFilesNameStorage).length == 0) {
      setIsFile(false);
    }
    setFileNameToDelete(fileName);
    setFileError('');
    setExcelFieldError('');
  };

  const deletFileData = () => {
    for (let key in inputFilesNameStorage) {
      if (inputFilesNameStorage[key] == fileNameToDelete) {
        setInputFilesNameStorage((prevState) => {
          const newState = { ...prevState };
          delete newState[fileNameToDelete];
          return newState;
        });
        setFileNameToDelete('');
      }
    }

    if (selectedListIdx === null) {
      return;
    }
    const updatedList = fileDataArray.filter((item) => item.id !== selectedListIdx);
    const updatedIDsList = updatedList.map((item) => {
      return item.id > selectedListIdx ? { ...item, id: item.id - 1 } : item;
    });
    setFileDataArray(updatedIDsList);
    setListDeleteDialog(false);

    if (!fileDataArray) {
      setListIdx(null);
    }
  };

  const closeDialog = () => {
    setShowScheduleDialogFlag(false);
  };

  useEffect(() => {
    const newNumbersLists = fileDataArray.map((fileData) => ({
      name: fileData.fileName,
      leads: fileData.columns.phone.map((phone: { toString: () => any }) => ({
        phone: phone.toString(),
      })),
    }));

    setValue('leadList', newNumbersLists);

    setNumbersLists(newNumbersLists);
  }, [fileDataArray]);

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name == 'retryMaxNumber' || name == 'retriesPerDay' || name == 'retryIntervalInMinutes') {
      const filteredValue = value.replace(/[^0-9.]/g, '');
      const parsedValue = parseFloat(filteredValue);

      if (name == 'retryMaxNumber') {
        setRetryMaxNumberValue(filteredValue);
        setRetryMaxNumberError('');
        setValue(name as FormFields, parsedValue);
      }

      if (name == 'retriesPerDay') {
        setRetriesPerDayValue(filteredValue);
        setRetriesPerDayError('');
        setValue(name as FormFields, parsedValue);
      }

      if (name == 'retryIntervalInMinutes') {
        setRetryIntervalInMinutesValue(filteredValue);
        setRetryIntervalInMinutesError('');
        setValue(name as FormFields, parsedValue);
      }
    } else {
      setValue(name as FormFields, value);
    }

    if (name == 'title') {
      setCampaignName(value);
    }
  };

  const handleAgentChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setAgent(value as string);
    let chosenAgent = agentList?.filter((currentAgent) => currentAgent.id == value);
    if (chosenAgent) {
      setAgentName(chosenAgent[0].mission.humanName);
    }
    setValue(name as FormFields, value);
  };

  const handleSubmit = () => {
    console.log('isFile', isFile);
    console.log('campaignName', campaignName);
    if (
      !isFile ||
      !campaignName ||
      !retryMaxNumberValue ||
      !retriesPerDayValue ||
      !retryIntervalInMinutesValue
    ) {
      if (!isFile) {
        setListError('Add phone numbers list');
      }

      if (!campaignName) {
        setCampaignNameError('Add campaign name');
      }

      if (!retryMaxNumberValue) {
        setRetryMaxNumberError('Add overall retry attempts');
      }

      if (!retriesPerDayValue) {
        setRetriesPerDayError('Add retries per day');
      }

      if (!retryIntervalInMinutesValue) {
        setRetryIntervalInMinutesError('Add retry delay');
      }
    } else {
      onSubmit();
      // inputFilesNameStorage = {};
    }

    if (retryMaxNumberValue) {
      setRetryMaxNumberError('');
    }
    if (retriesPerDayValue) {
      setRetriesPerDayError('');
    }
    if (retryIntervalInMinutesValue) {
      setRetryIntervalInMinutesError('');
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  let mastecheValues = { agentName, templateTextFieldValue, campaignName };

  return (
    <>
      <PageContainer>
        <Breadcrumb
          title={t('campaignPage.header.campaignName', { campaignName: 'Call Agent' })}
          items={BCrumb}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BlankCard>
              <form>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                      <CardContent>
                        <CustomFormLabel
                          sx={{
                            mt: 0,
                          }}
                          htmlFor="campaign-title">
                          {t('campaignPage.campaignName')}:
                        </CustomFormLabel>
                        <CustomTextField
                          control={control}
                          name="title"
                          onChange={handleOnChangeInput}
                          velue={campaignName}
                          id="campaign-title"
                          variant="outlined"
                          fullWidth
                          required
                        />
                        {!campaignName && (
                          <Typography component="p" sx={{ color: '#FF0000', width: '300px' }}>
                            {campaignNameError}
                          </Typography>
                        )}

                        <CustomFormLabel htmlFor="campaign-agent">
                          {t('campaignPage.selectAgent')}:
                        </CustomFormLabel>
                        <FormControl fullWidth variant="outlined">
                          {/* <InputLabel
                            id="campaign-agent"
                            sx={{
                              top: '-5px',
                              '&.MuiInputLabel-shrink': {
                                top: '0px',
                              },
                            }}>
                            Agent
                          </InputLabel> */}
                          <CustomSelect
                            control={control}
                            labelId="campaign-agent"
                            name="agentId"
                            onChange={handleAgentChange}
                            id="campaign-agent-id"
                            value={agent}>
                            {agentList &&
                              agentList.map((agent) => (
                                <MenuItem key={agent.id} value={agent.id}>
                                  {agent.name}
                                </MenuItem>
                              ))}
                          </CustomSelect>
                        </FormControl>

                        <CustomFormLabel htmlFor="campaign-agent">
                          {t('campaignPage.numberManage')}:
                        </CustomFormLabel>

                        {fileUploadingProgress != 0 ? (
                          <>
                            <LinearProgress variant="determinate" value={fileUploadingProgress} />
                          </>
                        ) : (
                          ''
                        )}
                        <ReactSortable
                          list={fileDataArray}
                          setList={(newlist) => setFileDataArray(newlist)}
                          ghostClass={classes.dropArea}
                          handle=".dragHandle"
                          filter=".ignoreDrag"
                          preventOnFilter={true}
                          className={classes.gridContainer}
                          onEnd={({ oldIndex, newIndex }) => onDragDropEnds(oldIndex, newIndex)}>
                          <>
                            {fileDataArray &&
                              fileDataArray.map((row) => {
                                return (
                                  <Grid className="dragHandle" key={row.id} item>
                                    <Tooltip title={row.fileName}>
                                      <Chip
                                        sx={{
                                          color: '#7C8FAC',
                                          width: '112px',
                                        }}
                                        icon={
                                          <IconFile
                                            color="#7C8FAC"
                                            style={{
                                              fontSize: '24px',
                                            }}
                                          />
                                        }
                                        label={
                                          <div
                                            style={{
                                              width: '46px',
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                            }}>
                                            {row.fileName}
                                          </div>
                                        }
                                        variant="outlined"
                                        onDelete={() => handleDeleteList(row.id, row.fileName)}
                                      />
                                    </Tooltip>
                                  </Grid>
                                );
                              })}
                            <Grid item>
                              <input
                                ref={inputFile}
                                type="file"
                                style={{ display: 'none' }}
                                accept=".xlsx,.xls"
                                onChange={handleFileChange}
                              />
                              <Button
                                onClick={() =>
                                  handleAddFileData().then(() => setExcelFieldError(''))
                                }
                                startIcon={<IconPlus />}
                                sx={{ marginBottom: '10px', borderRadius: '100px' }}>
                                {t('campaignPage.addList')}
                              </Button>
                              {listError && (
                                <Typography component="p" sx={{ color: '#FF0000', width: '300px' }}>
                                  {listError}
                                </Typography>
                              )}
                            </Grid>
                          </>
                        </ReactSortable>
                        {excelFieldError && (
                          <Typography
                            component="p"
                            sx={{ color: '#FF0000', whiteSpace: 'pre-wrap' }}>
                            {excelFieldError}
                          </Typography>
                        )}
                      </CardContent>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <CardContent>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            color: '#7C8FAC',
                            marginTop: '0px',
                            marginBottom: '20px',
                          }}>
                          {t('campaignPage.callSetting')}
                        </Typography>
                        <Button
                          onClick={() => setShowScheduleDialogFlag(true)}
                          startIcon={<IconCalendarEvent />}
                          variant="contained">
                          {t('campaignPage.changeSchedule')}
                        </Button>

                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <CustomFormLabel htmlFor="campaign-retry-attempts">
                              {t('campaignPage.overallRetryAttempt')}:
                            </CustomFormLabel>
                            <CustomTextField
                              control={control}
                              name="retryMaxNumber"
                              onChange={handleOnChangeInput}
                              type="text"
                              id="campaign-retry-attempts"
                              value={retryMaxNumberValue}
                              placeholder="Enter overall retry attempts"
                              fullWidth
                            />
                            {retryMaxNumberError && (
                              <Typography
                                component="p"
                                sx={{ color: '#FF0000', maxWidth: '300px' }}>
                                {retryMaxNumberError}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <CustomFormLabel htmlFor="campaign-retry-day">
                              {t('campaignPage.retryPerDay')}:
                            </CustomFormLabel>
                            <CustomTextField
                              control={control}
                              name="retriesPerDay"
                              onChange={handleOnChangeInput}
                              type="text"
                              id="campaign-retry-day"
                              value={retriesPerDayValue}
                              placeholder="Enter retries per day"
                              fullWidth
                            />
                            {retriesPerDayError && (
                              <Typography
                                component="p"
                                sx={{ color: '#FF0000', maxWidth: '300px' }}>
                                {retriesPerDayError}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>

                        <CustomFormLabel htmlFor="campaign-retry-delay">
                          {t('campaignPage.retryDelay')}
                        </CustomFormLabel>
                        <CustomTextField
                          control={control}
                          name="retryIntervalInMinutes"
                          onChange={handleOnChangeInput}
                          type="text"
                          id="campaign-retry-delay"
                          value={retryIntervalInMinutesValue}
                          placeholder="Enter retries delay"
                          fullWidth
                        />
                        {retryIntervalInMinutesError && (
                          <Typography component="p" sx={{ color: '#FF0000', maxWidth: '300px' }}>
                            {retryIntervalInMinutesError}
                          </Typography>
                        )}
                      </CardContent>
                    </Grid>
                  </Grid>
                  <CampaignTextField
                    mastecheValues={mastecheValues}
                    setTemplateTextFieldValue={setTemplateTextFieldValue}
                    setValue={setValue}
                    control={control}
                  />
                  <CardContent>
                    <Stack
                      sx={{ marginTop: '30px' }}
                      direction="row"
                      justifyContent="flex-end"
                      gap={3}>
                      <Button
                        onClick={handleSubmit}
                        type="button"
                        variant="contained"
                        color="primary">
                        {t('action.save')}
                      </Button>
                      <Button onClick={() => navigator(AuthenticatedPath.CAMPAIGN)}>Cancel</Button>
                    </Stack>
                  </CardContent>
                </CardContent>
              </form>
            </BlankCard>
          </Grid>
        </Grid>
        <Snackbar
          open={snackBarShow}
          autoHideDuration={2000}
          onClose={() => setSnackBarShow(false)}
          message={`${countOfPhoneNumbers} out of ${countOfPhoneNumbers} numbers were extracted`}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
        <SchedulePopup
          showDialogFlag={showScheduleDilaogFlag}
          closeDialog={closeDialog}
          setValue={setValue}
          schedule={schedule}
          setSchedule={setSchedule}
          startTime={startTime}
          endTime={endTime}
        />
        <ConfirmDeleteDialog
          open={listDeleteDialog}
          onClose={setListDeleteDialog}
          dialogTitle={'dialog.title1'}
          dialogContentText={'dialog.content'}
          showDialog={setListDeleteDialog}
          confirmDelete={deletFileData}
        />
      </PageContainer>
      {fileError ? <CustomAlert message={fileError} type={'error'} /> : ''}
    </>
  );
};
