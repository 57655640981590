import { useNavigate } from 'react-router-dom';

import { AuthLayout } from '@shared/layouts';
import { ForgotPasswordForm } from '@features/Auth/Forgot-Password/ui';

export const ForgotPassword = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout>
      <ForgotPasswordForm />
    </AuthLayout>
  );
};
