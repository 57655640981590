import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { useGetAllCampaignsControllerGetAllCampaignsQuery } from '@shared/services/apiService/apiService';
import { CampaignList } from './CampaignList';
import PageContainer from '@shared/components/container/PageContainer';
import Breadcrumb from '@shared/layouts/FullLayout/shared/breadcrumb/Breadcrumb';
import Welcome from '@shared/layouts/FullLayout/shared/welcome/Welcome';
import Spinner from '@shared/components/spinner/Spinner';
import { useAppDispatch } from '@shared/store';
import { actions } from '@shared/store/ducks';

import { AuthenticatedPath } from '@shared/constants/routes.constants';
import { useStyles } from './styles';
import { FilterCard } from '@shared/components/filterCard/FilterCard';

export const CampaignPage: React.FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    data: campaignList,
    isError,
    isLoading,
    refetch,
  } = useGetAllCampaignsControllerGetAllCampaignsQuery();
  const dispatch = useAppDispatch();
  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [pollingInterval, setPollingInterval] = useState(3000);

  useEffect(() => {
    const firstLogin = localStorage.getItem('firstLogin');

    if (!firstLogin) {
      localStorage.setItem('firstLogin', 'true');
      setIsFirstLogin(true);
    } else {
      setIsFirstLogin(false);
    }
    dispatch(actions.customizer.toggleLayout('full'));
  }, [dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch()
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setPollingInterval(3000);
          } else {
            setPollingInterval(1000);
          }
        })
        .catch(() => {});
    }, pollingInterval);

    return () => clearInterval(intervalId);
  }, [refetch, pollingInterval]);

  const BCrumb = [
    {
      to: AuthenticatedPath.CAMPAIGN,
      title: t('campaignPage.header.campaign'),
    },
    {
      title: '',
    },
  ];

  const setLoading = async (flag: boolean) => {
    await refetch();
    setLoadingFlag(flag);
  };

  if (isLoading || loadingFlag) {
    return <Spinner />;
  }

  return (
    <>
      <PageContainer title="Main" description="this is Admin main page">
        <Breadcrumb
          title={t('adminMainPage.header.companyName', { companyName: 'Call Agent' })}
          items={BCrumb}>
          <></>
        </Breadcrumb>

        <Box className={classes.main}>
          <FilterCard />
          <CampaignList
            onCampaignStatusUpdate={refetch}
            setLoading={setLoading}
            campaignList={campaignList ? campaignList : []}
          />
        </Box>
        {isFirstLogin ? <Welcome /> : <></>}
      </PageContainer>
    </>
  );
};
