import { useGetRecipientConversationControllerGetCampaignQuery } from '@shared/services/apiService/apiService';
import { useState } from 'react';

export const useGetRecipient = (campaignId: string) => {
  const [pollingInterval, setPollingInterval] = useState(1000);

  const {
    data: recipientData,
    refetch: recipientRefetch,
    isLoading: recipientIsLoading,
    isError: isErrorRecipient,
  } = useGetRecipientConversationControllerGetCampaignQuery(
    { id: campaignId! },
    { skip: !campaignId, refetchOnMountOrArgChange: true },
  );

  return {
    recipientData,
    isErrorRecipient,
    recipientRefetch,
    recipientIsLoading,
    pollingInterval,
    setPollingInterval,
  };
};
