import React, { useState, useLayoutEffect } from 'react';
import { Controller, Control, FormState, UseFormSetValue, useWatch } from 'react-hook-form';
import { Alert, Box, IconButton, InputAdornment, Link, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Email from '@mui/icons-material/Email';
import Lock from '@mui/icons-material/Lock';
import { SignInFormValues } from '../model/types';
import { useStyles } from './styles';
import { emailRules, passwordRules } from '@shared/constants/validation.constants';

interface SignInFormProps {
  title?: string;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  control: Control<SignInFormValues>;
  setValue: UseFormSetValue<SignInFormValues>;
  errorMsg: string;
  formState: FormState<SignInFormValues>;
}

export const SignInForm: React.FC<SignInFormProps> = ({
  title = 'Sign In',
  onSubmit,
  control,
  setValue,
  errorMsg,
  formState,
}) => {
  const { classes } = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const loginValue = useWatch({ control, name: 'login' });

  useLayoutEffect(() => {
    const trimLoginValue = loginValue.trim();
    setValue('login', trimLoginValue);
  }, [loginValue, setValue]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box className={classes.root} component="form" onSubmit={onSubmit}>
      <Box className={classes.header}>
        <Typography className={classes.title} variant="h3" component="div">
          {title}
        </Typography>
        <Typography className={classes.description} component="div">
          Please input the username and password
        </Typography>
        {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
      </Box>
      <Box className={classes.main}>
        <Controller
          name="login"
          control={control}
          defaultValue=""
          rules={emailRules}
          render={({ field: { ref, ...fieldProps } }) => (
            <TextField
              className={classes.user_input}
              size="small"
              color="info"
              id="outlined-textarea"
              label="Email"
              placeholder="Enter your Email"
              error={!!formState.errors.login}
              helperText={formState.errors.login ? formState.errors.login.message : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
              {...fieldProps}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={passwordRules}
          render={({ field }) => (
            <TextField
              fullWidth
              id="outlined-adornment-password"
              label="Password"
              placeholder="Enter your password"
              type={showPassword ? 'text' : 'password'}
              size="small"
              error={!!formState.errors.password}
              helperText={formState.errors.password ? formState.errors.password.message : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...field}
            />
          )}
        />
        <Box>
          <Link className={classes.forgot_btn} href="/forgot-password" underline="hover">
            Forgot Password?
          </Link>
        </Box>
        <Box className={classes.login_btn}>
          <Button fullWidth variant="contained" type="submit">
            Sign in
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
