export const emailRules = {
  required: 'Email is required',
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: 'Invalid email address',
  },
};

export const passwordRules = {
  required: 'Password is required',
  minLength: {
    value: 4,
    message: 'Password must have at least 4 characters',
  },
  validate: (value: string) =>
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{4,}$/.test(value) ||
    'Password must contain a special character and a number',
};
