interface Errors {
  leadList?: Record<string, ErrorLeadListItem[]>[] | string;
}

interface ErrorLeadListItem {
  leads?: Record<string, ErrorLead[]>;
}

type ErrorLead = Record<string, [string]>[];

interface ValidationError {
  leadListIndex?: string;
  leadIndex?: string;
  field: string;
  error: string;
}

export function extractLeadErrors(errors: Errors): string | null {
  const result: ValidationError[] = [];

  const leadListObject = errors?.leadList?.[0] || {};

  if (typeof leadListObject === 'string') {
    return leadListObject;
  }

  const leadListKeys = Object.keys(leadListObject);

  leadListKeys.map((leadListKey) => {
    const leadsObject: ErrorLead[] | undefined = (
      errors?.leadList as Record<string, ErrorLeadListItem[]>[] | undefined
    )?.[0]?.[leadListKey]?.[0]?.leads?.[0];
    const leadKeys = Object.keys(leadsObject || {});

    leadKeys.map((leadKey: string) => {
      const errorLead = leadsObject?.[leadKey as unknown as number];

      if (errorLead) {
        errorLead.forEach((obj) => {
          for (const [field, errors] of Object.entries(obj)) {
            errors.forEach((error) => {
              result.push({ leadListIndex: leadListKey, leadIndex: leadKey, field, error });
            });
          }
        });
      }
    });
  });

  return result.length > 0
    ? result
        .map(
          (error) =>
            `LeadList #${error.leadListIndex} - Lead #${error.leadIndex} - field: ${error.field} - error: ${error.error}`,
        )
        .join('\n')
    : null;
}
