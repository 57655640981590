import { tss } from 'tss-react/mui';

export const useStyles = tss.create(() => ({
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '800px',
        maxHeight: '1000px',
      },
    },
  },
  dialogTitle: {
    textAlign: 'center',
    padding: '30px 30px 20px 30px',
  },
  customizedButton: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    padding: 0,
  },
  dialogContent: {
    padding: '0px 30px 30px 30px',
  },
  userCard: {
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '16px',
    marginBottom: '16px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f9f9f9',
  },
  userCardContent: {
    flexGrow: 1,
  },
  removeButton: {
    marginLeft: '16px',
  },
  addButton: {
    marginTop: '16px',
  },
  errorMessage: {
    marginTop: '16px',
  },
  actionButtons: {
    marginTop: '20px',
  },
}));
