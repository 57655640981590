import { tss } from 'tss-react/mui';

export const useStyles = tss.create(() => ({
  root: {
    width: '100%',
    minWidth: '400px',
    maxWidth: '450px',
    padding: '55px 40px',
    borderRadius: '16px',
    border: '1px solid #0000001F',
  },
  header: {
    marginBottom: '15px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  logo: {},
  title: {
    fontSize: '30px',
  },
  description: {},
  main: {
    width: '100%',
    marginTop: '35px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '25px',
  },
  user_input: {
    display: 'flex',
    width: '100%',
  },
  pwd_input: {
    display: 'flex',
    width: '100%',
    margin: '0px',
  },
  forgot_btn: {
    marginTop: '24px',
  },
  login_btn: {
    alignSelf: 'end',
    width: '100%',
  },
}));
