import { tss } from 'tss-react/mui';

export const useStyles = tss.create(() => ({
  gridContainer: {
    marginTop: '15px',
    display: 'grid',
    gridGap: '8px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(112px, 1fr))',
    gridAutoRows: '40px',
  },
  dropArea: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      backgroundColor: '#ebebeb',
    },
  },
  tableheahder: {
    color: '#7C8FAC',
  },
  table_row: {
    cursor: 'pointer',
  },
  table_first_cell: {
    paddingLeft: '0px',
  },
  table_last_cell: {
    paddingRight: '0px',
  },
  table_sort_cell: {
    cursor: 'pointer',
    display: 'flex',
    gap: '4px',
  },
  table_th: {
    color: '#7C8FAC',
    fontSize: '14px',
  },
  table_popover: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
  },
  table_popover_button: {
    gap: '11px',
    justifyContent: 'start',
  },
}));
