import { CampaignRunDto } from '@shared/services/apiService/apiService';

import { CampaignStatus } from './CampaignStatusButton';

export const getCampaignStatus = (
  campaignRun: CampaignRunDto | null | undefined,
): CampaignStatus => {
  if (campaignRun?.status === 'finishing') return CampaignStatus.Stopping;
  if (campaignRun?.status === 'active') return CampaignStatus.Dialing;
  return CampaignStatus.NotStarted;
};
