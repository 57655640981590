import { useState, useCallback } from 'react';

const useTokenSwitcher = () => {
  const [originalToken, setOriginalToken] = useState<string>('');

  const switchToken = useCallback(
    async (newToken: string, action: () => Promise<void>) => {
      const currentToken = localStorage.getItem('accessToken') || '';
      setOriginalToken(currentToken);
      localStorage.setItem('accessToken', newToken);

      try {
        await action();
      } catch (error) {
        console.error('Action failed:', error);
      } finally {
        localStorage.setItem('accessToken', originalToken);
      }
    },
    [originalToken],
  );

  return { switchToken };
};

export default useTokenSwitcher;
