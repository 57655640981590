import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { t } from 'i18next';

interface ConfirmDeleteDialog {
  open: boolean;
  onClose: (value: React.SetStateAction<boolean>) => void;
  dialogTitle: string;
  dialogContentText: string;
  showDialog: (value: React.SetStateAction<boolean>) => void;
  confirmDelete: () => void | Promise<void>;
}

export const ConfirmDeleteDialog = ({
  open,
  onClose,
  dialogTitle,
  dialogContentText,
  showDialog,
  confirmDelete,
}: ConfirmDeleteDialog) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{t(dialogTitle)}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{t(dialogContentText)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => showDialog(false)}>
          {t('adminMainPage.operation.cancel')}
        </Button>
        <Button onClick={confirmDelete} autoFocus>
          {t('adminMainPage.operation.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
