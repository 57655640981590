import { FC } from 'react';

import { Link } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { styled } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as LogoIcon } from '@shared/assets/images/logos/title.svg';

import { RootState, useAppSelector } from '@shared/store';

const Logo: FC = () => {
  const customizer = useAppSelector((state: RootState) => state.customizer);
  const LinkStyled = styled(Link)(() => ({
    height: customizer.TopbarHeight,
    width: customizer.isCollapse ? '40px' : '180px',
    overflow: 'hidden',
    display: 'block',
  }));

  if (customizer.activeDir === 'ltr') {
    return (
      <LinkStyled
        to="/"
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}>
        {customizer.activeMode === 'dark' ? <LogoIcon /> : <LogoIcon />}

        {/* {customizer.activeMode === 'dark' ? <LogoLight /> : <LogoDark />} */}
      </LinkStyled>
    );
  }

  return (
    <LinkStyled
      to="/"
      style={{
        display: 'flex',
        alignItems: 'center',
      }}>
      {customizer.activeMode === 'dark' ? <LogoIcon /> : <LogoIcon />}
      {/* {customizer.activeMode === 'dark' ? <LogoDarkRTL /> : <LogoLightRTL />} */}
    </LinkStyled>
  );
};

export default Logo;
