import { Alert } from '@mui/material';

interface AlertProps {
  message: string;
  severity: 'success' | 'error' | 'info' | 'warning';
  clearAlert: () => void;
}

export const AlertMessage: React.FC<AlertProps> = ({
  message,
  severity,
  clearAlert,
}: AlertProps) => {
  return (
    <>
      {message && (
        <Alert style={{ marginTop: '20px' }} severity={severity} onClose={clearAlert}>
          {message}
        </Alert>
      )}
    </>
  );
};
