import { RoomAudioRenderer, useLocalParticipant } from '@livekit/components-react';
import { IconButton } from '@mui/material';

import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';

interface ActiveRoomProps {
  volume: number;
}

export const ActiveRoom: React.FC<ActiveRoomProps> = (props: ActiveRoomProps) => {
  const { volume } = props;

  const { isMicrophoneEnabled, localParticipant } = useLocalParticipant();

  return (
    <>
      <RoomAudioRenderer volume={volume / 100} />

      <IconButton
        onClick={() => {
          localParticipant.setMicrophoneEnabled(!isMicrophoneEnabled);
        }}>
        {isMicrophoneEnabled ? <MicIcon /> : <MicOffIcon />}
      </IconButton>
    </>
  );
};
