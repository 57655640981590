import { t } from 'i18next';
import { useRef, useState } from 'react';

export const useCampaignLogic = () => {
  let filesNameStorage: { [key: string]: string } = {};

  const [inputFilesNameStorage, setInputFilesNameStorage] = useState<{ [key: string]: string }>(
    filesNameStorage,
  );
  const inputFile = useRef<HTMLInputElement>(null);

  const [fileError, setFileError] = useState('');
  const [listError, setListError] = useState('');

  const handleAddFileData = async () => {
    await setFileError('');
    if (Object.keys(inputFilesNameStorage).length == 5) {
      setFileError(t('campaignPage.filesLimit'));
      return;
    }
    setListError('');
    inputFile?.current?.click();
  };

  return {
    filesNameStorage,
    inputFile,
    fileError,
    listError,
    handleAddFileData,
    setListError,
    setFileError,
    inputFilesNameStorage,
    setInputFilesNameStorage,
  };
};
