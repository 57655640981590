import { AuthorizedRoutes } from './AuthorizedRoutes';
import { UnauthorizedRoutes } from './UnauthorizedRoutes';
import { selectors } from '@shared/store/ducks/auth';
import { useAppSelector } from '@shared/store';
import { AuthorizedSuperAdminRoutes } from './AuthorizedSuperAdminRoutes';

export const Router = () => {
  const isAuth = useAppSelector(selectors.getAccessToken);
  const isSuperAdminAuth = useAppSelector(selectors.getSuperAdminAccessToken);

  if (isSuperAdminAuth) return <AuthorizedSuperAdminRoutes />;

  return <>{isAuth ? <AuthorizedRoutes /> : <UnauthorizedRoutes />}</>;
};
