import { GetAllCampaignsControllerGetAllCampaignsApiResponse } from '@shared/services/apiService/apiService';

export function runningCampaignsCount(
  campaignList: GetAllCampaignsControllerGetAllCampaignsApiResponse | undefined,
) {
  let activeCampaigns = campaignList?.filter(
    (campaign) => campaign?.campaignRun?.status == 'active',
  );
  return `${activeCampaigns?.length}`;
}

export function inactiveCampaignsCount(
  campaignList: GetAllCampaignsControllerGetAllCampaignsApiResponse | undefined,
) {
  let inactiveCampaigns = campaignList?.filter(
    (campaign) => campaign?.campaignRun?.status == 'inactive',
  );

  let campaignWithoutStatus = campaignList?.filter(
    (campaign) => campaign?.campaignRun?.status === undefined,
  );

  let total =
    inactiveCampaigns?.length && campaignWithoutStatus?.length
      ? inactiveCampaigns?.length + campaignWithoutStatus?.length
      : null;

  return `${total}`;
}

export function ongoingCallCount(
  campaignList: GetAllCampaignsControllerGetAllCampaignsApiResponse | undefined,
) {
  let count = campaignList?.reduce((total, campaign) => total + campaign.ongoingCallCount || 0, 0);

  return count || 0;
}
