import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';

import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Lock from '@mui/icons-material/Lock';

import { passwordRules } from '@shared/constants/validation.constants';
import { UnauthenticatedPath } from '@shared/constants/routes.constants';
import { useResetPassword } from '../hook/useResetPassword';
import { useStyles } from './styles';

export const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const {
    onSubmit,
    control,
    errorMsg,
    successMSg,
    formState: { errors },
  } = useResetPassword();

  const handleBackBtn = () => {
    navigate(UnauthenticatedPath.SIGN_IN);
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <Box className={classes.root}>
          <Box className={classes.header}>
            <Typography className={classes.title} variant="h3" component="div">
              {`Reset password`}
            </Typography>
            <Typography className={classes.description} component="div">
              {`Enter your new password`}
            </Typography>
            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
          </Box>
          <Box className={classes.main}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={passwordRules}
              render={({ field }) => (
                <TextField
                  fullWidth
                  id="outlined-adornment-password"
                  label="Password"
                  placeholder="Enter your password"
                  type={showPassword ? 'text' : 'password'}
                  size="small"
                  error={!!errors.password}
                  helperText={errors.password ? errors.password.message : null}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock />
                        {/* OR Use VpnKey if you prefer: <VpnKey /> */}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                />
              )}
            />
          </Box>
          <Box className={classes.footer}>
            <Button
              disabled={successMSg ? true : false}
              fullWidth
              variant="contained"
              type="submit">{`Reset Password`}</Button>
            <Button
              fullWidth
              variant="contained"
              type="button"
              onClick={handleBackBtn}>{`Back to signin`}</Button>
          </Box>
        </Box>
      </form>
    </>
  );
};
