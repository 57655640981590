export function formatTime(duration: number): string {
  const h = Math.floor(duration / 3600);
  const m = Math.floor((duration % 3600) / 60);
  const s = Math.floor((duration % 3600) % 60);

  return (
    (h > 0 ? h.toString().padStart(2, '0') + ':' : '') +
    m.toString().padStart(2, '0') +
    ':' +
    s.toString().padStart(2, '0')
  );
}
