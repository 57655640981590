import React from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import { Button, Stack, TextField, Box } from '@mui/material';
import { useStyles } from '../styles';
import { useTranslation } from 'react-i18next';
import { CreateCompanyForm } from './CreateCompanyForm';

interface UserCardProps {
  index: number;
  control: Control<CreateCompanyForm>;
  errors: FieldErrors<CreateCompanyForm>;
  remove: (index: number) => void;
}

const UserCard: React.FC<UserCardProps> = ({ index, control, errors, remove }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.userCard}>
      <Stack spacing={2} className={classes.userCardContent}>
        <Controller
          name={`users.${index}.userLogin`}
          control={control}
          rules={{
            required: t('superAdminMainPage.createCompany.errors.userLoginError'),
            maxLength: {
              value: 256,
              message: t('superAdminMainPage.createCompany.errors.userLoginError'),
            },
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: t('superAdminMainPage.createCompany.errors.userLoginError'),
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('superAdminMainPage.createCompany.company.name')}
              variant="outlined"
              fullWidth
              type="email"
              error={!!errors.users?.[index]?.userLogin}
              helperText={errors.users?.[index]?.userLogin?.message}
            />
          )}
        />
        <Controller
          name={`users.${index}.temporaryPassword`}
          control={control}
          rules={{
            required: t('superAdminMainPage.createCompany.errors.temporaryPasswordError'),
            maxLength: {
              value: 20,
              message: t('superAdminMainPage.createCompany.errors.temporaryPasswordError'),
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('superAdminMainPage.createCompany.company.user.temporaryPassword')}
              variant="outlined"
              fullWidth
              type="password"
              error={!!errors.users?.[index]?.temporaryPassword}
              helperText={errors.users?.[index]?.temporaryPassword?.message}
            />
          )}
        />
      </Stack>
      <Button
        type="button"
        variant="outlined"
        color="error"
        onClick={() => remove(index)}
        className={classes.removeButton}>
        {t('superAdminMainPage.createCompany.operation.removeUser')}
      </Button>
    </Box>
  );
};

export default UserCard;
