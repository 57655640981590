import { tss } from 'tss-react/mui';

export const useStyles = tss.create(() => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
  },
  agent: {
    padding: '30px',
  },
  agent_header: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'space-between',
  },
  agent_header_title: {
    display: 'flex',
    alignItems: 'center',
    placeItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
  },
  agent_table: {
    marginTop: '20px',
  },
  agent_table_row: {
    cursor: 'pointer',
  },
  agent_table_first_cell: {
    paddingLeft: '0px',
  },
  agent_table_sort_cell: {
    cursor: 'pointer',
    display: 'flex',
    gap: 4,
    paddingLeft: '0px',
  },
  agent_table_popover: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
  },
  agent_table_popover_button: {
    gap: '11px',
    justifyContent: 'start',
  },

  campaign: {
    padding: '30px',
  },
  campaign_header: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'space-between',
  },
  campaign_header_title: {
    display: 'flex',
    alignItems: 'center',
    placeItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
  },
  campaign_table: {
    marginTop: '20px',
  },
  campaign_table_row: {
    cursor: 'pointer',
  },
  campaign_table_first_cell: {
    paddingLeft: '0px',
  },
  campagin_table_sort_cell: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 3,
  },
  campagin_table_th: {
    flexBasis: 'min-content',
  },
  subtitle: {
    fontSize: 18,
  },
}));
