import { baseApi as api } from '../../store/api/baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    appControllerGetHello: build.query<
      AppControllerGetHelloApiResponse,
      AppControllerGetHelloApiArg
    >({
      query: () => ({ url: `/` }),
    }),
    metricsControllerWebhook: build.query<
      MetricsControllerWebhookApiResponse,
      MetricsControllerWebhookApiArg
    >({
      query: () => ({ url: `/metrics` }),
    }),
    signInControllerSignIn: build.mutation<
      SignInControllerSignInApiResponse,
      SignInControllerSignInApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/sign-in`,
        method: 'POST',
        body: queryArg.signInRequestDto,
      }),
    }),
    adminSignInControllerSignIn: build.mutation<
      AdminSignInControllerSignInApiResponse,
      AdminSignInControllerSignInApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/sign-in`,
        method: 'POST',
        body: queryArg.adminSignInRequestDto,
      }),
    }),
    forgotPasswordControllerForgotPassword: build.mutation<
      ForgotPasswordControllerForgotPasswordApiResponse,
      ForgotPasswordControllerForgotPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/forgot-password`,
        method: 'POST',
        body: queryArg.forgotPasswordRequestDto,
      }),
    }),
    resetPasswordControllerResetPassword: build.mutation<
      ResetPasswordControllerResetPasswordApiResponse,
      ResetPasswordControllerResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/reset-password`,
        method: 'POST',
        body: queryArg.resetPasswordRequestDto,
      }),
    }),
    userMeControllerUserMe: build.query<
      UserMeControllerUserMeApiResponse,
      UserMeControllerUserMeApiArg
    >({
      query: () => ({ url: `/v1/users/me` }),
    }),
    adminMeControllerAdminMe: build.query<
      AdminMeControllerAdminMeApiResponse,
      AdminMeControllerAdminMeApiArg
    >({
      query: () => ({ url: `/v1/admins/me` }),
    }),
    outboundTestCallToWebControllerUserMe: build.mutation<
      OutboundTestCallToWebControllerUserMeApiResponse,
      OutboundTestCallToWebControllerUserMeApiArg
    >({
      query: () => ({ url: `/v1/conversation/test/outbound-call-to-web`, method: 'POST' }),
    }),
    hangupTestCallOnWebControllerUserMe: build.mutation<
      HangupTestCallOnWebControllerUserMeApiResponse,
      HangupTestCallOnWebControllerUserMeApiArg
    >({
      query: () => ({ url: `/v1/conversation/test/hangup-call-on-web`, method: 'POST' }),
    }),
    outboundTestCallToWebV2ControllerUserMe: build.mutation<
      OutboundTestCallToWebV2ControllerUserMeApiResponse,
      OutboundTestCallToWebV2ControllerUserMeApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/conversation/test/outbound-call-to-web`,
        method: 'POST',
        body: queryArg.outboundTestCallToWebV2RequestDto,
      }),
    }),
    hangupTestCallOnWebV2ControllerHangup: build.mutation<
      HangupTestCallOnWebV2ControllerHangupApiResponse,
      HangupTestCallOnWebV2ControllerHangupApiArg
    >({
      query: () => ({ url: `/v2/conversation/test/hangup-call-on-web`, method: 'POST' }),
    }),
    outboundTestCallToPhoneControllerOutboundCall: build.mutation<
      OutboundTestCallToPhoneControllerOutboundCallApiResponse,
      OutboundTestCallToPhoneControllerOutboundCallApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/conversation/test/outbound-call-to-phone`,
        method: 'POST',
        body: queryArg.outboundTestCallToPhoneRequestDto,
      }),
    }),
    getOutboundTestCallToPhoneInfoControllerGetInfo: build.query<
      GetOutboundTestCallToPhoneInfoControllerGetInfoApiResponse,
      GetOutboundTestCallToPhoneInfoControllerGetInfoApiArg
    >({
      query: () => ({ url: `/v1/conversation/test/outbound-call-to-phone` }),
    }),
    startConversationRecordControllerLoadTestAgents: build.mutation<
      StartConversationRecordControllerLoadTestAgentsApiResponse,
      StartConversationRecordControllerLoadTestAgentsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/system/conversation/start-record`,
        method: 'POST',
        body: queryArg.startConversationRecordRequestDto,
      }),
    }),
    hangupTestCallOnPhoneControllerUserMe: build.mutation<
      HangupTestCallOnPhoneControllerUserMeApiResponse,
      HangupTestCallOnPhoneControllerUserMeApiArg
    >({
      query: () => ({ url: `/v1/conversation/test/hangup-call-on-phone`, method: 'POST' }),
    }),
    loadTestAgentsControllerLoadTestAgents: build.mutation<
      LoadTestAgentsControllerLoadTestAgentsApiResponse,
      LoadTestAgentsControllerLoadTestAgentsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/system/conversation/load-test-agents`,
        method: 'POST',
        body: queryArg.loadTestAgentsRequestDto,
      }),
    }),
    outboundMultipleCallsToPhoneControllerOutboundCalls: build.mutation<
      OutboundMultipleCallsToPhoneControllerOutboundCallsApiResponse,
      OutboundMultipleCallsToPhoneControllerOutboundCallsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/conversation/test/outbound-multiple-calls-to-phone`,
        method: 'POST',
        body: queryArg.outboundMultipleCallsToPhoneRequestDto,
      }),
    }),
    livekitServerHandlerControllerOutboundCall: build.mutation<
      LivekitServerHandlerControllerOutboundCallApiResponse,
      LivekitServerHandlerControllerOutboundCallApiArg
    >({
      query: () => ({ url: `/v1/livekit/handler`, method: 'POST' }),
    }),
    createCampaignControllerCreateCampaign: build.mutation<
      CreateCampaignControllerCreateCampaignApiResponse,
      CreateCampaignControllerCreateCampaignApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/campaign`,
        method: 'POST',
        body: queryArg.createCampaignRequestDto,
      }),
    }),
    createCampaignRunControllerCreateCampaignRun: build.mutation<
      CreateCampaignRunControllerCreateCampaignRunApiResponse,
      CreateCampaignRunControllerCreateCampaignRunApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/${queryArg.id}/run`, method: 'POST' }),
    }),
    createRecipientConversationSentimentControllerCreateRecipientConversationSentiment:
      build.mutation<
        CreateRecipientConversationSentimentControllerCreateRecipientConversationSentimentApiResponse,
        CreateRecipientConversationSentimentControllerCreateRecipientConversationSentimentApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/recipient-conversation/${queryArg.id}/add-sentiment`,
          method: 'POST',
          body: queryArg.recipientConversationSentimentRequestDto,
        }),
      }),
    deleteCampaignControllerDeleteCampaign: build.mutation<
      DeleteCampaignControllerDeleteCampaignApiResponse,
      DeleteCampaignControllerDeleteCampaignApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/${queryArg.id}`, method: 'DELETE' }),
    }),
    editCampaignControllerEditCampaign: build.mutation<
      EditCampaignControllerEditCampaignApiResponse,
      EditCampaignControllerEditCampaignApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/campaign/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.editCampaignRequestDto,
      }),
    }),
    getCampaignControllerGetCampaign: build.query<
      GetCampaignControllerGetCampaignApiResponse,
      GetCampaignControllerGetCampaignApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/${queryArg.id}` }),
    }),
    deleteLeadListControllerDeleteLeadList: build.mutation<
      DeleteLeadListControllerDeleteLeadListApiResponse,
      DeleteLeadListControllerDeleteLeadListApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/campaign/lead-list`,
        method: 'DELETE',
        body: queryArg.baseIdRequestDto,
      }),
    }),
    finishingCampaignControllerFinishingCampaigns: build.mutation<
      FinishingCampaignControllerFinishingCampaignsApiResponse,
      FinishingCampaignControllerFinishingCampaignsApiArg
    >({
      query: () => ({ url: `/v1/finishing-campaign`, method: 'POST' }),
    }),
    forceRunRecipientsControllerForceRunRecipients: build.mutation<
      ForceRunRecipientsControllerForceRunRecipientsApiResponse,
      ForceRunRecipientsControllerForceRunRecipientsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/system/campaign/force-run-recipients`,
        method: 'POST',
        body: queryArg.forceRunRecipientsRequestDto,
      }),
    }),
    getAllCampaignsControllerGetAllCampaigns: build.query<
      GetAllCampaignsControllerGetAllCampaignsApiResponse,
      GetAllCampaignsControllerGetAllCampaignsApiArg
    >({
      query: () => ({ url: `/v1/campaigns` }),
    }),
    getCampaignRunControllerGetCampaign: build.query<
      GetCampaignRunControllerGetCampaignApiResponse,
      GetCampaignRunControllerGetCampaignApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/${queryArg.id}/active-campaign-run` }),
    }),
    getRecipientConversationControllerGetCampaign: build.query<
      GetRecipientConversationControllerGetCampaignApiResponse,
      GetRecipientConversationControllerGetCampaignApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/campaign/${queryArg.id}/recipient-conversations`,
        params: {
          statuses: queryArg.statuses,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
        },
      }),
    }),
    partialUpdateRecipientConversationControllerPartialUpdateRecipientConversation: build.mutation<
      PartialUpdateRecipientConversationControllerPartialUpdateRecipientConversationApiResponse,
      PartialUpdateRecipientConversationControllerPartialUpdateRecipientConversationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/recipient-conversation/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.partialUpdateRecipientConversationRequestDto,
      }),
    }),
    processCampaignControllerProcessCampaign: build.mutation<
      ProcessCampaignControllerProcessCampaignApiResponse,
      ProcessCampaignControllerProcessCampaignApiArg
    >({
      query: () => ({ url: `/v1/process-recipients`, method: 'POST' }),
    }),
    stopCampaignRunControllerStopCampaignRun: build.mutation<
      StopCampaignRunControllerStopCampaignRunApiResponse,
      StopCampaignRunControllerStopCampaignRunApiArg
    >({
      query: (queryArg) => ({ url: `/v1/campaign/${queryArg.id}/stop`, method: 'POST' }),
    }),
    updateLeadListOrderControllerUpdateLeadListOrder: build.mutation<
      UpdateLeadListOrderControllerUpdateLeadListOrderApiResponse,
      UpdateLeadListOrderControllerUpdateLeadListOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/campaign/lead-list/order`,
        method: 'PATCH',
        body: queryArg.updateLeadListOrderRequestDto,
      }),
    }),
    terminateConversationControllerTerminateConversation: build.mutation<
      TerminateConversationControllerTerminateConversationApiResponse,
      TerminateConversationControllerTerminateConversationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/recipient-conversation/${queryArg.id}/terminate`,
        method: 'POST',
      }),
    }),
    getAgentControllerGetAgent: build.query<
      GetAgentControllerGetAgentApiResponse,
      GetAgentControllerGetAgentApiArg
    >({
      query: (queryArg) => ({ url: `/v1/agents/${queryArg.id}` }),
    }),
    editAgentControllerEditAgent: build.mutation<
      EditAgentControllerEditAgentApiResponse,
      EditAgentControllerEditAgentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/agents/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.editAgentRequestDto,
      }),
    }),
    deleteAgentControllerDeleteAgent: build.mutation<
      DeleteAgentControllerDeleteAgentApiResponse,
      DeleteAgentControllerDeleteAgentApiArg
    >({
      query: (queryArg) => ({ url: `/v1/agents/${queryArg.id}`, method: 'DELETE' }),
    }),
    getAgentSystemControllerGetAgent: build.query<
      GetAgentSystemControllerGetAgentApiResponse,
      GetAgentSystemControllerGetAgentApiArg
    >({
      query: (queryArg) => ({ url: `/v1/system/agents/${queryArg.id}` }),
    }),
    getAllAgentsControllerGetAllAgents: build.query<
      GetAllAgentsControllerGetAllAgentsApiResponse,
      GetAllAgentsControllerGetAllAgentsApiArg
    >({
      query: () => ({ url: `/v1/agents` }),
    }),
    createAgentControllerCreateAgent: build.mutation<
      CreateAgentControllerCreateAgentApiResponse,
      CreateAgentControllerCreateAgentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/agents`,
        method: 'POST',
        body: queryArg.createAgentRequestDto,
      }),
    }),
    getAllVoicesControllerGetAllVoices: build.query<
      GetAllVoicesControllerGetAllVoicesApiResponse,
      GetAllVoicesControllerGetAllVoicesApiArg
    >({
      query: () => ({ url: `/v1/voices` }),
    }),
    createCompanyControllerCreateCompany: build.mutation<
      CreateCompanyControllerCreateCompanyApiResponse,
      CreateCompanyControllerCreateCompanyApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/company`,
        method: 'POST',
        body: queryArg.createCompanyRequestDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiService };
export type AppControllerGetHelloApiResponse = /** status 200  */ string;
export type AppControllerGetHelloApiArg = void;
export type MetricsControllerWebhookApiResponse = unknown;
export type MetricsControllerWebhookApiArg = void;
export type SignInControllerSignInApiResponse = /** status 201  */ SignInResponseDto;
export type SignInControllerSignInApiArg = {
  signInRequestDto: SignInRequestDto;
};
export type AdminSignInControllerSignInApiResponse = /** status 201  */ AdminSignInResponseDto;
export type AdminSignInControllerSignInApiArg = {
  adminSignInRequestDto: AdminSignInRequestDto;
};
export type ForgotPasswordControllerForgotPasswordApiResponse =
  /** status 201  */ ForgotPasswordResponseDto;
export type ForgotPasswordControllerForgotPasswordApiArg = {
  forgotPasswordRequestDto: ForgotPasswordRequestDto;
};
export type ResetPasswordControllerResetPasswordApiResponse =
  /** status 201  */ ResetPasswordResponseDto;
export type ResetPasswordControllerResetPasswordApiArg = {
  resetPasswordRequestDto: ResetPasswordRequestDto;
};
export type UserMeControllerUserMeApiResponse = /** status 200  */ UserDto;
export type UserMeControllerUserMeApiArg = void;
export type AdminMeControllerAdminMeApiResponse = /** status 200  */ AdminDto;
export type AdminMeControllerAdminMeApiArg = void;
export type OutboundTestCallToWebControllerUserMeApiResponse =
  /** status 201  */ OutboundTestCallToWebResponseDto;
export type OutboundTestCallToWebControllerUserMeApiArg = void;
export type HangupTestCallOnWebControllerUserMeApiResponse =
  /** status 201  */ HangupTestCallOnWebResponseDto;
export type HangupTestCallOnWebControllerUserMeApiArg = void;
export type OutboundTestCallToWebV2ControllerUserMeApiResponse =
  /** status 201  */ OutboundTestCallToWebV2ResponseDto;
export type OutboundTestCallToWebV2ControllerUserMeApiArg = {
  outboundTestCallToWebV2RequestDto: OutboundTestCallToWebV2RequestDto;
};
export type HangupTestCallOnWebV2ControllerHangupApiResponse = /** status 201  */ BaseOkResponseDto;
export type HangupTestCallOnWebV2ControllerHangupApiArg = void;
export type OutboundTestCallToPhoneControllerOutboundCallApiResponse =
  /** status 201  */ CallInfoDto;
export type OutboundTestCallToPhoneControllerOutboundCallApiArg = {
  outboundTestCallToPhoneRequestDto: OutboundTestCallToPhoneRequestDto;
};
export type GetOutboundTestCallToPhoneInfoControllerGetInfoApiResponse =
  /** status 200  */ CallInfoDto;
export type GetOutboundTestCallToPhoneInfoControllerGetInfoApiArg = void;
export type StartConversationRecordControllerLoadTestAgentsApiResponse =
  /** status 201  */ StartConversationRecordResponseDto;
export type StartConversationRecordControllerLoadTestAgentsApiArg = {
  startConversationRecordRequestDto: StartConversationRecordRequestDto;
};
export type HangupTestCallOnPhoneControllerUserMeApiResponse = /** status 201  */ BaseOkResponseDto;
export type HangupTestCallOnPhoneControllerUserMeApiArg = void;
export type LoadTestAgentsControllerLoadTestAgentsApiResponse =
  /** status 201  */ LoadTestAgentsResponseDto;
export type LoadTestAgentsControllerLoadTestAgentsApiArg = {
  loadTestAgentsRequestDto: LoadTestAgentsRequestDto;
};
export type OutboundMultipleCallsToPhoneControllerOutboundCallsApiResponse =
  /** status 201  */ CallInfoDto[];
export type OutboundMultipleCallsToPhoneControllerOutboundCallsApiArg = {
  outboundMultipleCallsToPhoneRequestDto: OutboundMultipleCallsToPhoneRequestDto;
};
export type LivekitServerHandlerControllerOutboundCallApiResponse = unknown;
export type LivekitServerHandlerControllerOutboundCallApiArg = void;
export type CreateCampaignControllerCreateCampaignApiResponse = /** status 201  */ CampaignDto;
export type CreateCampaignControllerCreateCampaignApiArg = {
  createCampaignRequestDto: CreateCampaignRequestDto;
};
export type CreateCampaignRunControllerCreateCampaignRunApiResponse =
  /** status 201  */ CampaignRunDto;
export type CreateCampaignRunControllerCreateCampaignRunApiArg = {
  id: string;
};
export type CreateRecipientConversationSentimentControllerCreateRecipientConversationSentimentApiResponse =
  /** status 201  */ RecipientConversationSentimentDto;
export type CreateRecipientConversationSentimentControllerCreateRecipientConversationSentimentApiArg =
  {
    id: string;
    recipientConversationSentimentRequestDto: RecipientConversationSentimentRequestDto;
  };
export type DeleteCampaignControllerDeleteCampaignApiResponse =
  /** status 200  */ BaseOkResponseDto;
export type DeleteCampaignControllerDeleteCampaignApiArg = {
  id: string;
};
export type EditCampaignControllerEditCampaignApiResponse = /** status 200  */ CampaignDto;
export type EditCampaignControllerEditCampaignApiArg = {
  id: string;
  editCampaignRequestDto: EditCampaignRequestDto;
};
export type GetCampaignControllerGetCampaignApiResponse = /** status 200  */ CampaignDto;
export type GetCampaignControllerGetCampaignApiArg = {
  id: string;
};
export type DeleteLeadListControllerDeleteLeadListApiResponse =
  /** status 200  */ DeleteLeadListResponseDto | /** status 204  */ void;
export type DeleteLeadListControllerDeleteLeadListApiArg = {
  baseIdRequestDto: BaseIdRequestDto;
};
export type FinishingCampaignControllerFinishingCampaignsApiResponse =
  /** status 201  */ CampaignRunDto[];
export type FinishingCampaignControllerFinishingCampaignsApiArg = void;
export type ForceRunRecipientsControllerForceRunRecipientsApiResponse =
  /** status 201  */ ForceRunRecipientsResponseDto[];
export type ForceRunRecipientsControllerForceRunRecipientsApiArg = {
  forceRunRecipientsRequestDto: ForceRunRecipientsRequestDto;
};
export type GetAllCampaignsControllerGetAllCampaignsApiResponse =
  /** status 200  */ CampaignWithDetailedInfoDto[];
export type GetAllCampaignsControllerGetAllCampaignsApiArg = void;
export type GetCampaignRunControllerGetCampaignApiResponse = /** status 200  */ CampaignRunDto;
export type GetCampaignRunControllerGetCampaignApiArg = {
  id: string;
};
export type GetRecipientConversationControllerGetCampaignApiResponse =
  /** status 200  */ RecipientConversationDto[];
export type GetRecipientConversationControllerGetCampaignApiArg = {
  id: string;
  statuses?: string;
  sortBy?: string;
  sortOrder?: string;
};
export type PartialUpdateRecipientConversationControllerPartialUpdateRecipientConversationApiResponse =
  /** status 200  */ BaseOkResponseDto;
export type PartialUpdateRecipientConversationControllerPartialUpdateRecipientConversationApiArg = {
  id: string;
  partialUpdateRecipientConversationRequestDto: PartialUpdateRecipientConversationRequestDto;
};
export type ProcessCampaignControllerProcessCampaignApiResponse =
  /** status 201  */ RecipientConversationDto[];
export type ProcessCampaignControllerProcessCampaignApiArg = void;
export type StopCampaignRunControllerStopCampaignRunApiResponse = /** status 201  */ CampaignRunDto;
export type StopCampaignRunControllerStopCampaignRunApiArg = {
  id: string;
};
export type UpdateLeadListOrderControllerUpdateLeadListOrderApiResponse =
  /** status 200  */ UpdateLeadListOrderResponseDto;
export type UpdateLeadListOrderControllerUpdateLeadListOrderApiArg = {
  updateLeadListOrderRequestDto: UpdateLeadListOrderRequestDto;
};
export type TerminateConversationControllerTerminateConversationApiResponse =
  /** status 201  */ RecipientConversationDto;
export type TerminateConversationControllerTerminateConversationApiArg = {
  id: string;
};
export type GetAgentControllerGetAgentApiResponse = /** status 200  */ AgentDto;
export type GetAgentControllerGetAgentApiArg = {
  id: string;
};
export type EditAgentControllerEditAgentApiResponse = /** status 200  */ AgentDto;
export type EditAgentControllerEditAgentApiArg = {
  id: string;
  editAgentRequestDto: EditAgentRequestDto;
};
export type DeleteAgentControllerDeleteAgentApiResponse = /** status 200  */ BaseOkResponseDto;
export type DeleteAgentControllerDeleteAgentApiArg = {
  id: string;
};
export type GetAgentSystemControllerGetAgentApiResponse = /** status 200  */ AgentDto;
export type GetAgentSystemControllerGetAgentApiArg = {
  id: string;
};
export type GetAllAgentsControllerGetAllAgentsApiResponse = /** status 200  */ AgentDto[];
export type GetAllAgentsControllerGetAllAgentsApiArg = void;
export type CreateAgentControllerCreateAgentApiResponse = /** status 201  */ AgentDto;
export type CreateAgentControllerCreateAgentApiArg = {
  createAgentRequestDto: CreateAgentRequestDto;
};
export type GetAllVoicesControllerGetAllVoicesApiResponse = /** status 200  */ VoiceDto[];
export type GetAllVoicesControllerGetAllVoicesApiArg = void;
export type CreateCompanyControllerCreateCompanyApiResponse = /** status 201  */ CompanyDto;
export type CreateCompanyControllerCreateCompanyApiArg = {
  createCompanyRequestDto: CreateCompanyRequestDto;
};
export type SignInResponseDto = {
  accessToken: string;
};
export type SignInRequestDto = {
  login: string;
  password: string;
};
export type AdminSignInResponseDto = {
  accessToken: string;
};
export type AdminSignInRequestDto = {
  login: string;
  password: string;
};
export type ForgotPasswordResponseDto = {
  message: string;
};
export type ForgotPasswordRequestDto = {
  email: string;
};
export type ResetPasswordResponseDto = {
  message: string;
};
export type ResetPasswordRequestDto = {
  token: string;
  password: string;
};
export type UserRoles = 'admin';
export type UserDto = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  webTestPhone: string | null;
  role: UserRoles;
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};
export type AdminRoles = 'manager';
export type AdminDto = {
  id: string;
  email: string;
  role: AdminRoles;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};
export type OutboundTestCallToWebResponseDto = {
  ok: boolean;
  conversationId: string;
};
export type HangupTestCallOnWebResponseDto = {
  ok: boolean;
  conversationId: string | null;
};
export type MetadataDto = {
  agentId?: string;
};
export type CallInfoDto = {
  id: string;
  creationTime: string;
  roomName: string;
  metadata?: MetadataDto;
};
export type OutboundTestCallToWebV2ResponseDto = {
  ok: boolean;
  callInfo: CallInfoDto;
  roomAccessToken: string;
};
export type OutboundTestCallToWebV2RequestDto = {
  agentId: string;
};
export type BaseOkResponseDto = {
  ok: boolean;
};
export type OutboundTestCallToPhoneRequestDto = {
  agentId: string;
  calleePhone: string;
  trunkName?: string | null;
};
export type StartConversationRecordResponseDto = {
  egressId: string;
  roomId: string;
  roomName: string;
};
export type StartConversationRecordRequestDto = {
  roomName: string;
};
export type LoadTestAgentsResponseDto = {
  ok: boolean;
  agentsInfoRan: CallInfoDto[];
};
export type LoadTestAgentsRequestDto = {
  numberOfAgents?: number;
};
export type OutboundMultipleCallsToPhoneRequestDto = {
  agentId: string;
  calleePhones: string[];
  trunkName?: string | null;
};
export type CampaignLeadDto = {
  phone: string;
  id: string;
  name: string | null;
  email: string | null;
  leadListId: string;
  createdAt: string;
  updatedAt: string;
};
export type CampaignLeadListDto = {
  name: string;
  order: number;
  campaignId: string;
  leads: CampaignLeadDto[];
  createdAt: string;
  updatedAt: string;
  id: string;
};
export type CallScheduleDto = {
  mondayStart?: string | null;
  mondayEnd?: string | null;
  tuesdayStart?: string | null;
  tuesdayEnd?: string | null;
  wednesdayStart?: string | null;
  wednesdayEnd?: string | null;
  thursdayStart?: string | null;
  thursdayEnd?: string | null;
  fridayStart?: string | null;
  fridayEnd?: string | null;
  saturdayStart?: string | null;
  saturdayEnd?: string | null;
  sundayStart?: string | null;
  sundayEnd?: string | null;
  timezone?: string;
};
export type CampaignRunStatusType = 'active' | 'inactive' | 'finishing';
export type CampaignRunRecipientStatusType =
  | 'pending'
  | 'processing'
  | 'calling'
  | 'completed'
  | 'failed'
  | 'terminated';
export type RecipientInteractionResultType =
  | 'follow_up_completed'
  | 'follow_up_required'
  | 'callback_requested'
  | 'callback_completed'
  | 'do_not_call';
export type RecipientConversationSentimentType = 'positive' | 'negative' | 'neutral';
export type SentimentScoreDto = {
  label: RecipientConversationSentimentType;
  score: number;
};
export type RecipientConversationSentimentDto = {
  sentimentScores: SentimentScoreDto[];
  createdAt: string;
  id: string;
  recipientConversationId: string;
  updatedAt: string;
};
export type RecipientConversationDto = {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: CampaignRunRecipientStatusType;
  interactionResult?: RecipientInteractionResultType;
  leadId: string;
  campaignRunId: string;
  failReason?: string;
  startedAt?: string;
  answeredAt?: string;
  terminatedAt?: string;
  callbackAt?: string;
  lead: CampaignLeadDto;
  sentiments?: RecipientConversationSentimentDto[];
};
export type CampaignRunDto = {
  id: string;
  status: CampaignRunStatusType;
  startTime: string;
  endTime?: string;
  campaignId: string;
  agentId: string;
  createdAt: string;
  updatedAt: string;
  recipientConversations?: RecipientConversationDto;
};
export type CampaignDto = {
  title: string;
  description: string;
  retryIntervalInMinutes: number;
  retryMaxNumber: number;
  retriesPerDay: number;
  followUpTemplate: string | null;
  agentId: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  companyId: string;
  leadList: CampaignLeadListDto[];
  callSchedule?: CallScheduleDto;
  campaignRun?: CampaignRunDto | null;
};
export type LeadSimpleDto = {
  phone: string;
};
export type LeadListSimpleDto = {
  name: string;
  leads: LeadSimpleDto[];
};
export type CallScheduleRequestDto = {
  mondayStart?: string | null;
  mondayEnd?: string | null;
  tuesdayStart?: string | null;
  tuesdayEnd?: string | null;
  wednesdayStart?: string | null;
  wednesdayEnd?: string | null;
  thursdayStart?: string | null;
  thursdayEnd?: string | null;
  fridayStart?: string | null;
  fridayEnd?: string | null;
  saturdayStart?: string | null;
  saturdayEnd?: string | null;
  sundayStart?: string | null;
  sundayEnd?: string | null;
  timezone?: string;
};
export type CreateCampaignRequestDto = {
  title: string;
  description: string;
  retryIntervalInMinutes: number;
  retryMaxNumber: number;
  retriesPerDay: number;
  followUpTemplate: string | null;
  agentId: string;
  createdAt: string;
  updatedAt: string;
  leadList: LeadListSimpleDto[];
  callSchedule?: CallScheduleRequestDto;
};
export type RecipientConversationSentimentRequestDto = {
  sentimentScores: SentimentScoreDto[];
  createdAt: string;
};
export type EditCampaignRequestDto = {
  title?: string;
  description?: string;
  retryIntervalInMinutes?: number;
  retryMaxNumber?: number;
  retriesPerDay?: number;
  followUpTemplate?: string | null;
  agentId?: string;
  createdAt?: string;
  updatedAt?: string;
  leadList?: LeadListSimpleDto[];
  callSchedule?: CallScheduleRequestDto;
};
export type DeleteLeadListResponseDto = {
  deletedLeadsCount: number;
  leadListDeleted: boolean;
};
export type BaseIdRequestDto = {
  id: string;
};
export type ForceRunRecipientsResponseDto = {
  ok: boolean;
  timezone: string;
  campaignId: string;
  conversationId: string;
  roomName: string;
  phone: string;
};
export type RecipientIdWithPriorityDto = {
  /** Conversation ID that will be forcibly pushed to the message broker queue. */
  recipientConversationId: string;
  priority?: number | null;
};
export type RoutingKeys = 'eu' | 'uae';
export type ForceRunRecipientsRequestDto = {
  recipients: RecipientIdWithPriorityDto[];
  region: RoutingKeys;
  trunkName?: string | null;
};
export type CampaignWithDetailedInfoDto = {
  title: string;
  description: string;
  retryIntervalInMinutes: number;
  retryMaxNumber: number;
  retriesPerDay: number;
  followUpTemplate: string | null;
  agentId: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  companyId: string;
  leadList: CampaignLeadListDto[];
  callSchedule?: CallScheduleDto;
  campaignRun?: CampaignRunDto | null;
  pendingLeadsCount: number;
  reachedLeadsCount: number;
  unreachedLeadsCount: number;
  processingLeadCounts: number;
  ongoingCallCount: number;
  plannedRecallCount: number;
  activeRunsCount: number;
  totalLeads: number;
};
export type PartialUpdateRecipientConversationRequestDto = {
  status: CampaignRunRecipientStatusType;
  interactionResult?: RecipientInteractionResultType;
  failReason?: string;
  callbackAt?: string;
};
export type OrderItemDto = {
  id: string;
  order: number;
};
export type UpdateLeadListOrderResponseDto = {
  changedOrder: OrderItemDto[];
};
export type ListOrderUpdate = {
  id: string;
  order: number;
};
export type UpdateLeadListOrderRequestDto = {
  updates: ListOrderUpdate[];
};
export type AgentModels = 'gpt-4o' | 'gpt-4-turbo' | 'gpt-4' | 'gpt-3.5-turbo';
export type VoiceDto = {
  id: string;
  voiceId: string;
  name: string;
  previewUrl: string;
  gender?: string;
  accent?: string;
  createdAt: string;
  updatedAt: string;
};
export type MissionDto = {
  id: string;
  humanName: string;
  intro: string;
  goal: string;
  offerDetails: string;
  farewell: string;
  createdAt: string;
  updatedAt: string;
};
export type AgentDto = {
  id: string;
  name: string;
  model: AgentModels;
  voiceId: string;
  companyId: string;
  missionId: string;
  langfusePromptId: string;
  createdAt: string;
  updatedAt: string;
  voice: VoiceDto;
  mission: MissionDto;
};
export type EditMissionRequestDto = {
  humanName: string;
  intro: string;
  goal: string;
  offerDetails: string;
  farewell: string;
};
export type EditAgentRequestDto = {
  name: string;
  model: AgentModels;
  voiceId: string;
  mission: EditMissionRequestDto;
};
export type CreateMissionRequestDto = {
  humanName: string;
  intro: string;
  goal: string;
  offerDetails: string;
  farewell: string;
};
export type CreateAgentRequestDto = {
  name: string;
  model: AgentModels;
  voiceId: string;
  mission: CreateMissionRequestDto;
};
export type CompanyDto = {
  id: string;
  name: string;
  description: string | null;
  industryType: string | null;
  address: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};
export type UserRequestDto = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  webTestPhone: string | null;
  role: UserRoles;
};
export type CreateCompanyRequestDto = {
  name: string;
  description: string | null;
  users: UserRequestDto[];
};
export const {
  useAppControllerGetHelloQuery,
  useMetricsControllerWebhookQuery,
  useSignInControllerSignInMutation,
  useAdminSignInControllerSignInMutation,
  useForgotPasswordControllerForgotPasswordMutation,
  useResetPasswordControllerResetPasswordMutation,
  useUserMeControllerUserMeQuery,
  useAdminMeControllerAdminMeQuery,
  useOutboundTestCallToWebControllerUserMeMutation,
  useHangupTestCallOnWebControllerUserMeMutation,
  useOutboundTestCallToWebV2ControllerUserMeMutation,
  useHangupTestCallOnWebV2ControllerHangupMutation,
  useOutboundTestCallToPhoneControllerOutboundCallMutation,
  useGetOutboundTestCallToPhoneInfoControllerGetInfoQuery,
  useStartConversationRecordControllerLoadTestAgentsMutation,
  useHangupTestCallOnPhoneControllerUserMeMutation,
  useLoadTestAgentsControllerLoadTestAgentsMutation,
  useOutboundMultipleCallsToPhoneControllerOutboundCallsMutation,
  useLivekitServerHandlerControllerOutboundCallMutation,
  useCreateCampaignControllerCreateCampaignMutation,
  useCreateCampaignRunControllerCreateCampaignRunMutation,
  useCreateRecipientConversationSentimentControllerCreateRecipientConversationSentimentMutation,
  useDeleteCampaignControllerDeleteCampaignMutation,
  useEditCampaignControllerEditCampaignMutation,
  useGetCampaignControllerGetCampaignQuery,
  useDeleteLeadListControllerDeleteLeadListMutation,
  useFinishingCampaignControllerFinishingCampaignsMutation,
  useForceRunRecipientsControllerForceRunRecipientsMutation,
  useGetAllCampaignsControllerGetAllCampaignsQuery,
  useGetCampaignRunControllerGetCampaignQuery,
  useGetRecipientConversationControllerGetCampaignQuery,
  usePartialUpdateRecipientConversationControllerPartialUpdateRecipientConversationMutation,
  useProcessCampaignControllerProcessCampaignMutation,
  useStopCampaignRunControllerStopCampaignRunMutation,
  useUpdateLeadListOrderControllerUpdateLeadListOrderMutation,
  useTerminateConversationControllerTerminateConversationMutation,
  useGetAgentControllerGetAgentQuery,
  useEditAgentControllerEditAgentMutation,
  useDeleteAgentControllerDeleteAgentMutation,
  useGetAgentSystemControllerGetAgentQuery,
  useGetAllAgentsControllerGetAllAgentsQuery,
  useCreateAgentControllerCreateAgentMutation,
  useGetAllVoicesControllerGetAllVoicesQuery,
  useCreateCompanyControllerCreateCompanyMutation,
} = injectedRtkApi;
