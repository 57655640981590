import { AuthLayout } from '@shared/layouts';
import { SignInForm } from '@features/Auth';
import { useSignIn } from '@features/Auth/SignIn/hooks/useSignIn';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SuperAdminUnauthenticatedPath } from '@shared/constants/routes.constants';
import { useStyles } from '../styles';

export const SignInPage = () => {
  const { onSubmit, control, setValue, errorMsg, formState } = useSignIn();
  const navigate = useNavigate();
  const styles = useStyles();

  return (
    <AuthLayout>
      <div className={styles.classes.root}>
        <Button onClick={() => navigate(SuperAdminUnauthenticatedPath.SIGN_IN)}>
          Sign in As Root Admin
        </Button>
        <SignInForm
          onSubmit={onSubmit}
          control={control}
          setValue={setValue}
          errorMsg={errorMsg}
          formState={formState}
        />
      </div>
    </AuthLayout>
  );
};
