import { useNavigate } from 'react-router-dom';

import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';

import { useStyles } from './styles';
import { Email } from '@mui/icons-material';
import { AuthLayout } from '@shared/layouts';
import { UnauthenticatedPath } from '@shared/constants/routes.constants';

export const VerifyCode = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const handleBackBtn = () => {
    navigate(UnauthenticatedPath.SIGN_IN);
  };

  const handleSubmitBtn = () => {
    navigate(UnauthenticatedPath.RESET);
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmitBtn}>
        <Box className={classes.root}>
          <Box className={classes.header}>
            <Typography className={classes.title} variant="h3" component="div">
              {`Verify your code`}
            </Typography>
            <Typography className={classes.description} component="div">
              {`We sent the verification code to your email. Please check that and input the code.`}
            </Typography>
          </Box>
          <Box className={classes.main}>
            <TextField
              className={classes.user_input}
              size="small"
              color="info"
              id="outlined-textarea"
              label="Email"
              placeholder="Enter your Email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box className={classes.footer}>
            <Button fullWidth variant="contained" type="submit">{`Verify`}</Button>
            <Button fullWidth variant="contained" type="button">{`ReSend Email`}</Button>

            <Button
              fullWidth
              variant="contained"
              type="button"
              onClick={handleBackBtn}>{`Back to signin`}</Button>
          </Box>
        </Box>
      </form>
    </AuthLayout>
  );
};
