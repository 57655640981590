import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useForgotPasswordControllerForgotPasswordMutation } from '@shared/services/apiService/apiService';

import { ForgotFormValue } from '../modal/type';

export const useForgotPassword = () => {
  const navigator = useNavigate();
  const [useForgotPassword] = useForgotPasswordControllerForgotPasswordMutation();
  const [errorMsg, setErrorMsg] = useState('');
  const [successMSg, setSuccessMsg] = useState('');

  const { handleSubmit, setValue, ...formMethods } = useForm<ForgotFormValue>({
    mode: 'onTouched',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    const res: any = await useForgotPassword({
      forgotPasswordRequestDto: {
        email: data.email,
      },
    });

    if (res.error) {
      setSuccessMsg('');
      if (res.error.data) {
        setErrorMsg(res.error.data.message);
      } else {
        setErrorMsg('An unknown error occurred.');
      }
    } else {
      setErrorMsg('');
      setSuccessMsg('We just sent the email. Please check.');
    }
  });

  return {
    setValue,
    onSubmit,
    errorMsg,
    successMSg,
    ...formMethods,
  };
};
