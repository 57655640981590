import { Box, Grid, Stack, Typography, styled } from '@mui/material';
import { ReactComponent as VisualAgentIcon } from '@shared/assets/images/svgs/visual-agents.svg';
import { ReactComponent as VisualCampaignIcon } from '@shared/assets/images/svgs/visual-campaigns.svg';
import { ReactComponent as VisualRunningCampaignIcon } from '@shared/assets/images/svgs/visual-running-campaign.svg';
import { ReactComponent as VisualInactiveCampaignIcon } from '@shared/assets/images/svgs/visual-inactive-campaign.svg';
import { useGetAllCampaignsControllerGetAllCampaignsQuery } from '@shared/services/apiService/apiService';
import { useMemo } from 'react';
import {
  inactiveCampaignsCount,
  ongoingCallCount,
  runningCampaignsCount,
} from './FilterCardCalculation';
import { t } from 'i18next';

const BoxStyled = styled(Box)(() => ({
  padding: '19px 24px',
  transition: '0.1s ease-in',
  cursor: 'pointer',
  color: 'inherit',
  '&:hover': {
    transform: 'scale(1.03)',
  },
}));

export const FilterCard = () => {
  const { data: campaignList } = useGetAllCampaignsControllerGetAllCampaignsQuery();

  let countCall = useMemo(() => ongoingCallCount(campaignList), [campaignList]);
  let activeCampaigns = useMemo(() => runningCampaignsCount(campaignList), [campaignList]);
  let inactiveCampaigns = useMemo(() => inactiveCampaignsCount(campaignList), [campaignList]);

  return (
    <Grid container spacing={3} textAlign="center">
      <Grid item lg={3} sm={6} xs={12}>
        <BoxStyled
          onClick={() => {}}
          sx={{ backgroundColor: 'primary.light', color: 'primary.main' }}>
          <Stack direction="row" justifyContent="flex-start" spacing="20px" alignItems="center">
            <VisualAgentIcon />
            <Box>
              <Typography variant="h3" textAlign="start">
                {countCall}
              </Typography>
              <Typography variant="h6">{t('adminMainPage.cardFilter.ongoingCalls')}</Typography>
            </Box>
          </Stack>
        </BoxStyled>
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <BoxStyled
          onClick={() => {}}
          sx={{ backgroundColor: 'warning.light', color: 'warning.main' }}>
          <Stack direction="row" justifyContent="flex-start" spacing="20px" alignItems="center">
            <VisualCampaignIcon />
            <Box>
              <Typography variant="h3" textAlign="start">
                {campaignList?.length}
              </Typography>
              <Typography variant="h6">{t('adminMainPage.cardFilter.totalCampaign')}</Typography>
            </Box>
          </Stack>
        </BoxStyled>
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <BoxStyled
          onClick={() => {}}
          sx={{ backgroundColor: 'success.light', color: 'success.main' }}>
          <Stack direction="row" justifyContent="flex-start" spacing="20px" alignItems="center">
            <VisualRunningCampaignIcon />
            <Box>
              <Typography variant="h3" textAlign="start">
                {activeCampaigns}
              </Typography>
              <Typography variant="h6">{t('adminMainPage.cardFilter.runningCampaign')}</Typography>
            </Box>
          </Stack>
        </BoxStyled>
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <BoxStyled onClick={() => {}} sx={{ backgroundColor: 'error.light', color: 'error.main' }}>
          <Stack direction="row" justifyContent="flex-start" spacing="20px" alignItems="center">
            <VisualInactiveCampaignIcon />
            <Box>
              <Typography variant="h3" textAlign="start">
                {inactiveCampaigns}
              </Typography>
              <Typography variant="h6">{t('adminMainPage.cardFilter.inactiveCampaign')}</Typography>
            </Box>
          </Stack>
        </BoxStyled>
      </Grid>
    </Grid>
  );
};
