import { actions } from '@shared/store/ducks';
import { useAppDispatch } from '@shared/store';
import { useNavigate } from 'react-router-dom';
import { SuperAdminUnauthenticatedPath } from '@shared/constants/routes.constants';

export const useSignOutSuperAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onLogout = () => {
    localStorage.removeItem('firstLogin');
    dispatch(actions.auth.signOutSuperAdmin());
    navigate(SuperAdminUnauthenticatedPath.SIGN_IN);
  };

  return onLogout;
};
