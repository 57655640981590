import 'src/shared/styles/fonts.scss';
import 'src/shared/styles/colors.scss';
import 'src/shared/styles/common.scss';

import { CssBaseline, ThemeProvider } from '@mui/material';

import { ThemeSettings } from '@shared/theme/Theme';
import { RootState, useAppSelector } from '@shared/store';
import RTL from '@shared/layouts/FullLayout/shared/customizer/RTL';
import ScrollToTop from '@shared/components/shared/ScrollToTop';
import { DayjsProvider } from './dayjs';
import { Router } from './router';
import { AccessModal } from '@widgets/Popup/AccessModal/AccessModal';

const App = () => {
  const theme = ThemeSettings();
  const customizer = useAppSelector((state: RootState) => state.customizer);

  return (
    <ThemeProvider theme={theme}>
      <AccessModal />
      <DayjsProvider>
        <RTL direction={customizer.activeDir}>
          <CssBaseline />
          <ScrollToTop>
            <Router />
          </ScrollToTop>
        </RTL>
      </DayjsProvider>
    </ThemeProvider>
  );
};

export default App;
