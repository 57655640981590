import { tss } from 'tss-react/mui';

export const useStyles = tss.create(() => ({
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '800px',
        maxHeight: '95vh',
        // overflowY: 'unset',
      },
    },
  },
  dialogTitle: {
    textAlign: 'center',
    padding: '30px 30px 20px 30px',
  },
  customizedButton: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    padding: 0,
  },
  dialogContent: {
    padding: '0px 30px, 30px, 30px',
  },
  chatBox: {
    width: '100%',
    backgroundColor: '#F2F6FA',
  },
  table_header_cell: {
    padding: 0,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  table_row_cell: {
    paddingLeft: '0px',
    paddingRight: '10px',
  },
  table_row_last_cell: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  columnName: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#7C8FAC',
  },
}));
