import { Navigate, Route, Routes } from 'react-router-dom';
import { SuperAdminAuthenticatedPath } from '@shared/constants/routes.constants';
import { MainPage } from '@pages/SuperAdmin/Main';
import { FullLayout } from '@shared/layouts';

export const AuthorizedSuperAdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FullLayout />}>
        <Route path="/" element={<MainPage />} />
        <Route path={SuperAdminAuthenticatedPath.CREATE_COMPANY} element={<MainPage />} />
        <Route
          path="*"
          element={<Navigate to={SuperAdminAuthenticatedPath.CREATE_COMPANY} replace />}
        />
      </Route>
    </Routes>
  );
};
