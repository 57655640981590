import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, Tooltip } from '@mui/material';
import { IconRefresh } from '@tabler/icons-react';

import { useGetAllAgentsControllerGetAllAgentsQuery } from '@shared/services/apiService/apiService';
import { AgentList } from './AgentList';
import PageContainer from '@shared/components/container/PageContainer';
import Breadcrumb from '@shared/layouts/FullLayout/shared/breadcrumb/Breadcrumb';
import Spinner from '@shared/components/spinner/Spinner';

import { AuthenticatedPath } from '@shared/constants/routes.constants';
import { useStyles } from './styles';
import { FilterCard } from '@shared/components/filterCard/FilterCard';

export const AgentPage: React.FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    data: agentList,
    isLoading,
    refetch,
  } = useGetAllAgentsControllerGetAllAgentsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [loadingFlag, setLoadingFlag] = useState(false);

  const BCrumb = [
    {
      to: AuthenticatedPath.AGENT,
      title: t('agentPage.header.agent'),
    },
    {
      title: '',
    },
  ];

  const setLoading = async (flag: boolean) => {
    await refetch();
    setLoadingFlag(flag);
  };

  if (isLoading || loadingFlag) {
    return <Spinner />;
  }

  return (
    <>
      <PageContainer title="Call Agent" description="Call Agent">
        <Breadcrumb
          title={t('adminMainPage.header.companyName', { companyName: 'Call Agent' })}
          items={BCrumb}>
          <Box sx={{ display: 'flex', alignContent: 'start', alignItems: 'start' }}>
            <Tooltip title="Refresh">
              <IconButton onClick={() => {}}>
                <IconRefresh size="24" color="#49BEFF" />
              </IconButton>
            </Tooltip>
          </Box>
        </Breadcrumb>
        <Box className={classes.main}>
          <FilterCard />
          <AgentList setLoading={setLoading} agentList={agentList ? agentList : []} />
        </Box>
      </PageContainer>
    </>
  );
};
