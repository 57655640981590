import { useState } from 'react';

interface UseAlertProps {
  initialMessage?: string;
  initialSeverity?: 'success' | 'error' | 'info' | 'warning';
}

export const useAlert = ({
  initialMessage = '',
  initialSeverity = 'success',
}: UseAlertProps = {}) => {
  const [message, setMessage] = useState(initialMessage);
  const [severity, setSeverity] = useState(initialSeverity);

  const setAlert = (msg: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setMessage(msg);
    setSeverity(severity);
  };

  const clearAlert = () => {
    setMessage('');
  };

  return {
    message,
    severity,
    setAlert,
    clearAlert,
  };
};
