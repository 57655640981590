import { useNavigate } from 'react-router-dom';

import { Alert, Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { Email } from '@mui/icons-material';
import { useStyles } from './styles';
import { emailRules } from '@shared/constants/validation.constants';
import { Controller } from 'react-hook-form';
import { useForgotPassword } from '../hook/useForgotPassword';
import { UnauthenticatedPath } from '@shared/constants/routes.constants';

export const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const {
    onSubmit,
    control,
    setValue,
    errorMsg,
    successMSg,
    formState: { errors },
  } = useForgotPassword();

  const handleBackBtn = () => {
    navigate(UnauthenticatedPath.SIGN_IN);
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <Box className={classes.root}>
          <Box className={classes.header}>
            <Typography className={classes.title} variant="h3" component="div">
              {`Forgot password`}
            </Typography>
            <Typography className={classes.description} component="div">
              {`Enter your email address below and we will send you a link to reset your password.`}
            </Typography>
            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
            {successMSg && <Alert severity="success">{successMSg}</Alert>}
          </Box>
          <Box className={classes.main}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={emailRules}
              render={({ field: { ref, ...fieldProps } }) => (
                <TextField
                  className={classes.user_input}
                  size="small"
                  color="info"
                  id="outlined-textarea"
                  label="Email"
                  placeholder="Enter your Email"
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : null}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                  {...fieldProps}
                />
              )}
            />
          </Box>
          <Box className={classes.footer}>
            <Button fullWidth variant="contained" type="submit">
              {successMSg ? 'Send again' : 'Send Email'}
            </Button>
            <Button
              fullWidth
              variant="contained"
              type="button"
              onClick={handleBackBtn}>{`Back to signin`}</Button>
          </Box>
        </Box>
      </form>
    </>
  );
};
