import { useState, useEffect } from 'react';

const useTimer = (startTimestamp: bigint | null) => {
  const [duration, setDuration] = useState<number>(0);

  useEffect(() => {
    if (startTimestamp != null) {
      let currentTime = BigInt(Math.floor(Date.now() / 1000));
      setDuration(Number(currentTime - startTimestamp));
      const timerId = setInterval(() => {
        setDuration((prevTime) => prevTime + 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [startTimestamp]);

  return duration;
};

export default useTimer;
