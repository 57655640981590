import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemAvatar,
  Stack,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import PhonePausedOutlinedIcon from '@mui/icons-material/PhonePausedOutlined';

import Scrollbar from '@shared/components/custom-scroll/Scrollbar';

import { useStyles } from './styles';

interface MonitorCampaignProps {
  showDialogFlag: boolean;
  closeDialog: () => void;
}

export const MonitorCampaignPopup: React.FC<MonitorCampaignProps> = ({
  showDialogFlag,
  closeDialog,
}: MonitorCampaignProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      className={classes.dialog}
      open={showDialogFlag}
      onClose={() => closeDialog()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle} variant="h6">
        +32 00 00 00 00
        {/* <FlagOutlinedIcon color="primary" /> */}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item xs={12} mt="20px">
            <Stack direction="row" justifyContent="space-between">
              <Typography color="#7C8FAC" fontWeight="600" fontSize="14px">
                Duration:
              </Typography>
              <Typography color="#7C8FAC" fontWeight="600" fontSize="14px">
                00:00
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} mt="10px">
            <Box className={classes.chatBox}>
              <Scrollbar sx={{ height: '400px', overflow: 'auto', maxHeight: '800px' }}>
                <Box p={3}>
                  <Box>
                    <Box display="flex">
                      <ListItemAvatar>
                        <Avatar
                          src="/src/shared/assets/images/profile/user-1.jpg"
                          sx={{ width: 40, height: 40 }}
                        />
                      </ListItemAvatar>
                      <Box>
                        <Typography variant="body2" color="grey.400" mb={1}>
                          James Johnson, 3 hours ago
                        </Typography>

                        <Box
                          mb={2}
                          sx={{
                            p: 1,
                            backgroundColor: 'white',
                            mr: 'auto',
                            maxWidth: '320px',
                          }}>
                          Kis afboud lejifa pihider iboip ewhuka saw doruhti ihakisril liw.
                        </Box>
                      </Box>
                    </Box>
                    <Box mb={1} display="flex" alignItems="flex-end" flexDirection="row-reverse">
                      <Box alignItems="flex-end" display="flex" flexDirection={'column'}>
                        <Typography variant="body2" color="grey.400" mb={1}>
                          1 hour ago
                        </Typography>
                        <Box
                          mb={1}
                          sx={{
                            p: 1,
                            backgroundColor: 'white',
                            ml: 'auto',
                            maxWidth: '320px',
                          }}>
                          dfasdfasdfasdfasd
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex">
                      <ListItemAvatar>
                        <Avatar
                          src="/src/shared/assets/images/profile/user-1.jpg"
                          sx={{ width: 40, height: 40 }}
                        />
                      </ListItemAvatar>
                      <Box>
                        <Typography variant="body2" color="grey.400" mb={1}>
                          James Johnson, 3 hours ago
                        </Typography>

                        <Box
                          mb={2}
                          sx={{
                            p: 1,
                            backgroundColor: 'white',
                            mr: 'auto',
                            maxWidth: '320px',
                          }}>
                          Kis afboud lejifa pihider iboip ewhuka saw doruhti ihakisril liw.
                        </Box>
                      </Box>
                    </Box>
                    <Box mb={1} display="flex" alignItems="flex-end" flexDirection="row-reverse">
                      <Box alignItems="flex-end" display="flex" flexDirection={'column'}>
                        <Typography variant="body2" color="grey.400" mb={1}>
                          1 hour ago
                        </Typography>
                        <Box
                          mb={1}
                          sx={{
                            p: 1,
                            backgroundColor: 'white',
                            ml: 'auto',
                            maxWidth: '320px',
                          }}>
                          dfasdfasdfasdfasd
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Scrollbar>
            </Box>
          </Grid>

          <Grid item xs={12} mt="20px">
            <Button startIcon={<PhonePausedOutlinedIcon />} variant="text" color="error" fullWidth>
              Terminate a call
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <IconButton
          className={classes.customizedButton}
          onClick={() => {
            closeDialog();
          }}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};
