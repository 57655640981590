import {
  useEditCampaignControllerEditCampaignMutation,
  useGetAllCampaignsControllerGetAllCampaignsQuery,
} from '@shared/services/apiService/apiService';
import { ResponseMsg } from '@shared/types/admin/agent';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CreateCampaignFormValue } from '../model/type';
import { useNavigate } from 'react-router-dom';
import { extractLeadErrors } from '../utils/extract-lead-errors';

export const useEditCampaign = () => {
  const [useEditCampaign] = useEditCampaignControllerEditCampaignMutation();
  const [responseMsg, setResponseMsg] = useState<ResponseMsg>({
    message: '',
    type: 'success',
  });
  const [responseLoadingFlag, setResponseLoadingFlag] = useState(false);
  const [editCampaignError, setEditCampaignError] = useState('');
  const [excelFieldError, setExcelFieldError] = useState('');

  const { refetch } = useGetAllCampaignsControllerGetAllCampaignsQuery();

  const { handleSubmit, setValue, reset, ...formMethods } = useForm<CreateCampaignFormValue>({
    mode: 'all',
    defaultValues: {
      title: '',
      agentId: '',
    },
  });

  const navigate = useNavigate();

  const handleLocationMain = () => {
    navigate('/');
  };

  const onSubmit = handleSubmit(async (data) => {
    setResponseMsg({
      message: '',
      type: 'success',
    });

    try {
      useEditCampaign({
        editCampaignRequestDto: {
          title: data.title,
          description: 'test',
          retryIntervalInMinutes: data.retryIntervalInMinutes,
          retryMaxNumber: data.retryMaxNumber,
          retriesPerDay: data.retriesPerDay,
          agentId: data.agentId,
          createdAt: data.createdAt,
          updatedAt: new Date().toISOString(),
          leadList: data.leadList,
          callSchedule: data.callSchedule,
          followUpTemplate: data.followUpTemplate,
        },
        id: data.id,
      })
        .unwrap()
        .then(async (payload) => {
          setResponseLoadingFlag(true);
          await refetch();
          handleLocationMain();
          setResponseMsg({
            message: 'Successfully created',
            type: 'success',
          });
        })
        .catch((error) => {
          if (error.status == 400) {
            const leadErrors = extractLeadErrors(error?.data || {});

            if (leadErrors) {
              setExcelFieldError(leadErrors);
            }
          }

          setEditCampaignError(error.data.message);
          setResponseMsg({
            message: 'Something went wrong',
            type: 'error',
          });
        });
      setResponseLoadingFlag(false);
    } catch (error) {
      setResponseMsg({
        message: 'Something Went wrong',
        type: 'error',
      });
    }
  });

  return {
    setValue,
    reset,
    onSubmit,
    setResponseMsg,
    responseMsg,
    responseLoadingFlag,
    editCampaignError,
    setEditCampaignError,
    excelFieldError,
    setExcelFieldError,
    ...formMethods,
  };
};
