// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as React from 'react';
import { Snackbar, Alert, AlertTitle } from '@mui/material';
import { ResponseMsg } from '@shared/types/admin/agent';

interface CustomAlert {
  message: string;
  type: 'success' | 'error';
  setResponseMessage?: React.Dispatch<React.SetStateAction<ResponseMsg>>;
}

const CustomAlert = ({ message, type }: CustomAlert) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  React.useEffect(() => {
    // Update the document title using the browser API
    const timer = setTimeout(() => {
      handleClick();
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <React.Fragment>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={type}
          variant="filled"
          sx={{ width: '100%', color: 'white' }}>
          <AlertTitle>{message}</AlertTitle>
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default CustomAlert;
