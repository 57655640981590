import React from 'react';
import { Typography } from '@mui/material';
import useTimer from '@shared/hooks/userTimer';
import { formatTime } from '@shared/utils/FormatTime';

interface TimerProps {
  startTimestamp: bigint | null;
}

const Timer: React.FC<TimerProps> = ({ startTimestamp }) => {
  const duration = useTimer(startTimestamp);

  return (
    <Typography color="#7C8FAC" fontWeight="600" fontSize="14px">
      {formatTime(duration)}
    </Typography>
  );
};

export default Timer;
