import { tss } from 'tss-react/mui';

export const useStyles = tss.create(() => ({
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '450px',
        maxHeight: '1000px',
        overflowY: 'unset',
      },
    },
  },
  dialogTitle: {
    textAlign: 'center',
    padding: '30px 30px 20px 30px',
  },
  customizedButton: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    padding: 0,
  },
  dialogContent: {
    padding: '0px 30px, 30px, 30px',
  },
  chatBox: {
    width: '100%',
    backgroundColor: '#F2F6FA',
  },
}));
