import { useNavigate } from 'react-router-dom';

import { useStyles } from './styles';
import { AuthLayout } from '@shared/layouts';
import { ResetPasswordForm } from '@features/Auth/Reset-Password';
import { UnauthenticatedPath } from '@shared/constants/routes.constants';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const handleBackBtn = () => {
    navigate(UnauthenticatedPath.SIGN_IN);
  };

  return (
    <AuthLayout>
      <ResetPasswordForm />
    </AuthLayout>
  );
};
