import { Button } from '@mui/material';
import { CreateCompanyPopup } from '@widgets/Popup/CreateCompany/CreateCompany';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const MainPage = () => {
  const [showDialogFlag, setShowDialogFlag] = useState(false);
  const { t } = useTranslation();

  const closeCreateCompanyDialog = () => setShowDialogFlag(false);

  return (
    <div>
      <Button onClick={() => setShowDialogFlag(true)}>
        {t('superAdminMainPage.createCompany.operation.createCompany')}
      </Button>
      <CreateCompanyPopup showDialogFlag={showDialogFlag} closeDialog={closeCreateCompanyDialog} />
    </div>
  );
};
