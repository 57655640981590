import React from 'react';
import { Typography, IconButton, Stack } from '@mui/material';
import { IconPlayerStop, IconPlayerPlay } from '@tabler/icons-react';
import { CampaignDto } from '@shared/services/apiService/apiService';
import { getCampaignStatus } from './utils';
import i18n from '@shared/utils/i18n';

export enum CampaignStatus {
  NotStarted = 'not-started',
  Dialing = 'dialing',
  Stopping = 'stopping',
}

interface CampaignStatusButtonProps {
  campaign?: CampaignDto | null;
  disabled?: boolean;
  onClick?: (id: string, campaignStatus: CampaignStatus) => void;
  statusLabels?: Record<string, string>;
}

const DEFAULT_STATUS_MAPPINGS: Record<string, string> = {
  stopText: 'Stop',
  startText: i18n.t('adminMainPage.operation.start'),
  stop: i18n.t('adminMainPage.operation.stop'),
  stopping: i18n.t('adminMainPage.operation.stopping'),
};

const getCampaignStatusFragment = (
  campaignStatus: CampaignStatus,
  { stopText, startText, stoppingText } = DEFAULT_STATUS_MAPPINGS,
) => {
  if (campaignStatus === CampaignStatus.Dialing) {
    return (
      <>
        <IconPlayerStop size={18} color="#FA896B" />
        <Typography fontWeight="bold">{stopText}</Typography>
      </>
    );
  } else if (campaignStatus === CampaignStatus.NotStarted) {
    return (
      <>
        <IconPlayerPlay size={18} color="#59a32f" />
        <Typography fontWeight="bold">{startText}</Typography>
      </>
    );
  } else if (campaignStatus === CampaignStatus.Stopping) {
    return <Typography fontWeight="bold">{stoppingText}</Typography>;
  }
};

const CampaignStatusButton: React.FC<CampaignStatusButtonProps> = ({
  campaign,
  disabled,
  onClick,
  statusLabels = {},
}) => {
  const campaignStatus = getCampaignStatus(campaign?.campaignRun);

  return (
    <IconButton
      size="small"
      disabled={disabled}
      onClick={() => {
        onClick?.(campaign?.id as string, campaignStatus);
      }}>
      <Stack alignItems="center" direction="row" gap={1}>
        {getCampaignStatusFragment(campaignStatus, statusLabels)}
      </Stack>
    </IconButton>
  );
};

export default CampaignStatusButton;
