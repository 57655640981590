import { Navigate, Route, Routes } from 'react-router-dom';
import {
  AgentPage,
  CampaignPage,
  CreateAgentPage,
  CreateCampaignPage,
  EditCampaignPage,
} from '@pages/Admin';
import { AuthenticatedPath } from '@shared/constants/routes.constants';
import { FullLayout } from '@shared/layouts';

export const AuthorizedRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FullLayout />}>
        <Route path="/" element={<CampaignPage />} />
        <Route path={AuthenticatedPath.CAMPAIGN} element={<CampaignPage />} />
        <Route path={AuthenticatedPath.AGENT} element={<AgentPage />} />
        <Route path={AuthenticatedPath.CREATE_AGENT} element={<CreateAgentPage />} />
        <Route path={AuthenticatedPath.CREATE_CAMPAIGN} element={<CreateCampaignPage />} />
        <Route path={AuthenticatedPath.EDIT_AGENT} element={<CreateAgentPage />} />
        <Route path={AuthenticatedPath.EDIT_CAMPAIGN} element={<EditCampaignPage />} />
        <Route path="*" element={<Navigate to={AuthenticatedPath.CAMPAIGN} replace />} />
      </Route>
    </Routes>
  );
};
