import { uniqueId } from 'lodash';

interface MenuitemsType {
  [x: string]: any;
  id?: string;
  navlabel?: boolean;
  subheader?: string;
  title?: string;
  icon?: any;
  href?: string;
  children?: MenuitemsType[];
  chip?: string;
  chipColor?: string;
  variant?: string;
  external?: boolean;
}

import { IconAperture, IconPackage } from '@tabler/icons-react';
import i18n from '@shared/utils/i18n';

export const AdminMenuItems: MenuitemsType[] = [
  {
    id: uniqueId(),
    title: i18n.t('adminMainPage.menuItems.campaignList'),
    icon: IconAperture,
    href: '/admin/campaign',
  },
  {
    id: uniqueId(),
    title: i18n.t('adminMainPage.menuItems.agentList'),
    icon: IconPackage,
    href: '/admin/agent',
  },
];

export const SuperAdminMenuItems: MenuitemsType[] = [
  {
    id: uniqueId(),
    title: i18n.t('superAdminMainPage.menuItems.createCompany'),
    icon: IconAperture,
    href: '/super-admin/company/new',
  },
];

export default AdminMenuItems;
