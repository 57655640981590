import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';
import { store } from '../store';
import { actions } from '../ducks/auth';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_CONVERSATION_API_URL || 'localhost:4000',
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
      return headers;
    }
    return headers;
  },
  paramsSerializer: (params) => {
    return queryString.stringify(params);
  },
  async responseHandler(response) {
    // convert to JSON as usual
    const data = await response.clone().json();

    if (response.status === 401) {
      store.dispatch(actions.signOut());
      return { error: { status: 401, data: 'Unauthorized-Token Expired' } };
    }

    return data;
  },
});

export const baseApi = createApi({
  reducerPath: 'conversationApi',
  baseQuery,
  endpoints: () => ({}),
});
